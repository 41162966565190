import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import config from '../../config.js';

function UserActivation() {
  const { token } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  // Jelszó kritériumok ellenőrzése
  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleActivation = async () => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Hiba',
        text: 'A jelszavak nem egyeznek!',
      });
      return;
    }
  
    if (!isPasswordValid(password)) {
      Swal.fire({
        icon: 'error',
        title: 'Hiba',
        text: 'A jelszónak legalább 8 karakterből kell állnia, tartalmaznia kell kis- és nagybetűt, valamint számot is!',
      });
      return;
    }
  
    try {
      const response = await fetch(`${config.serverAddress}/api/activate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, username, password }),
      });
  
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Siker',
          text: 'Felhasználó sikeresen aktiválva!',
        }).then(() => {
          navigate('/login');
        });
      } else if (response.status === 400) {
        const data = await response.json();
        if (data.message === "Username already exists") {
          Swal.fire({
            icon: 'error',
            title: 'Hiba',
            text: 'A megadott felhasználónév már létezik. Válasszon egy másikat.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Hiba',
            text: data.message || 'Ismeretlen hiba történt.',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Hiba',
          text: 'A felhasználó aktiválása nem sikerült!',
        });
      }
    } catch (error) {
      console.error('Fetch error:', error);
      Swal.fire({
        icon: 'error',
        title: 'Szerver hiba',
        text: 'Nem sikerült kapcsolatba lépni a szerverrel.',
      });
    }
  };

  return (
    <div>
      <h2>Felhasználó Aktiválása</h2>
      <input
        type="text"
        placeholder="Felhasználónév"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Jelszó"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Jelszó megerősítése"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <button onClick={handleActivation}>Aktiválás</button>
    </div>
  );
}

export default UserActivation;
