import React from 'react';
import './AddingButton.css';  // The updated CSS filename.
import icon_add from '../../icons/icon_add.png'

const AddingButton = ({ label, onClick }) => {  // onClick esemény
    return (
        <button className="adding-button" onClick={onClick}>
            <img src={icon_add} alt="add icon" className="adding-icon" />
            <span className="adding-text">{label}</span>
        </button>
    );
};

export default AddingButton;