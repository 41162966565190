import React, { useState } from 'react';
import Row from '../Row/Row';
import './DynamicFormAsset.css';
import AddingButton from '../Buttons/AddingButton';
import TertiaryButton from '../Buttons/TertiaryButton';


const DynamicFormAssetExta = ({ formData, onInputChange, onSave, onCancel }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [productQuantityExternal, setProductQuantityExternal]= useState('')

  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="dynamic-form">
      <div className="button-container">
        {!isFormVisible && (
          <AddingButton label="Külső raktárból" onClick={toggleFormVisibility} />
        )}
      </div>
      {isFormVisible && (
        <div className="d-form-container visible">
          <div className="rows-container">
            
            <h4 className='title'>KÜLSŐ RAKTÁR</h4>
            <Row
                elements={[
                  {
                    type: 'selectInputNumber',
                    label: 'Mennyiség:',
                    placeholder: 'ha nincs raktáron, külső készlet',
                    inputValue: productQuantityExternal,
                    onInputChange: (e) => onInputChange('totalPersonnel', e.target.value),
                    max: 30,
                  },
                  {
                    type: 'selectInputNumber',
                    label: 'Bekerülési Értéke:',
                    placeholder: 'ezer öccá',
                    inputValue: productQuantityExternal,
                    onInputChange: (e) => onInputChange('totalPersonnel', e.target.value),
                  },
                ]}
            />
            {/* Add more Row components here as needed */}
          </div>
          
          <div className="dynamic-button-row-extra">
            <TertiaryButton label="MÉGSE" onClick={() => {
              setFormVisible(false);
              if (onCancel) onCancel();
            }} />
            
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicFormAssetExta;
