import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './InventoryFilter.css';
import AddProductModal from '../AddProductModal/AddProductModal.js';
import AddAccessoryModal from '../AddAccessoryModal/AddAccessoryModal.js';
import AddPartModal from '../AddPartModal/AddPartModal.js';
import icon_filter from '../../icons/icon_filter.png';
import icon_search from '../../icons/icon_search.png';

const InventoryFilter = ({ onCategoryChange, onDataUpdate, onFilterChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Termékek');
  const [isProductModalVisible, setProductModalVisible] = useState(false);
  const [isAccessoryModalVisible, setAccessoryModalVisible] = useState(false);
  const [isPartModalVisible, setPartModalVisible] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const [filterCategories, setFilterCategories] = useState({
    Termékek: [],
    Kiegészítők: [],
    Alkatrészek: [],
  });

  // Adatok betöltése az adatbázisból
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/inventory/categories');
        const data = await response.json();
        setFilterCategories(data);
      } catch (error) {
        console.error('Nem sikerült betölteni a kategóriákat:', error);
      }
    };
    fetchCategories();
  }, [onDataUpdate]); // Frissítési függőség

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleItemClick = (item) => {
    setActiveItem(item);
    setDropdownOpen(false);
    onCategoryChange(item);
  };

  const handleCategoryCheck = (categoryName) => {
    setCheckedCategories(prevState => {
      // Az aktuális állapot ellenőrzése
      const isChecked = prevState[categoryName]?.checked;
      const updatedSubcategories = {};
      const category = filterCategories[activeItem].find(cat => cat.name === categoryName);
  
      // Minden alkategória állapotát frissítjük a fő kategória állapotához igazodva
      category.subcategories.forEach((sub) => {
        updatedSubcategories[sub.name] = !isChecked;
      });
  
      const newCheckedCategories = {
        ...prevState,
        [categoryName]: {
          checked: !isChecked, // Fő kategória állapotát is megváltoztatjuk
          subcategories: updatedSubcategories, // Frissítjük az alkategóriákat is
        },
      };
  
      onFilterChange(newCheckedCategories); // Küldjük az új állapotot a szülő komponensnek
      return newCheckedCategories;
    });
  };
  
  const handleSubcategoryCheck = (categoryName, groupName) => {
    setCheckedCategories(prevState => {
      const updatedSubcategories = {
        ...prevState[categoryName]?.subcategories,
        [groupName]: !prevState[categoryName]?.subcategories?.[groupName],
      };
  
      const newCheckedCategories = {
        ...prevState,
        [categoryName]: {
          ...prevState[categoryName],
          subcategories: updatedSubcategories,
        },
      };
  
      onFilterChange(newCheckedCategories);
      return newCheckedCategories;
    });
  };

  const handleNewItem = () => {
    if (activeItem === 'Termékek') {
      setProductModalVisible(true);
    } else if (activeItem === 'Kiegészítők') {
      setAccessoryModalVisible(true);
    } else if (activeItem === 'Alkatrészek') {
      setPartModalVisible(true);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Hiba!',
        text: `Nem lehet új elemet hozzáadni a "${activeItem}" kategóriához.`,
      });
    }
  };

  const handleProductFormSubmit = async (productData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/addProduct', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Termékek',
          ...productData,
        }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új terméket hozzáadtuk!', 'success');
        setProductModalVisible(false);
        onDataUpdate(); // Adat frissítése
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült hozzáadni az új terméket: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  const handleAccessoryFormSubmit = async (accessoryData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/addAccessory', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Kiegészítők',
          ...accessoryData,
        }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új kiegészítőt hozzáadtuk!', 'success');
        setAccessoryModalVisible(false);
        onDataUpdate(); // Adat frissítése
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült hozzáadni az új kiegészítőt: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  const handlePartFormSubmit = async (partData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/addPart', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Alkatrészek',
          ...partData,
        }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új alkatrészt hozzáadtuk!', 'success');
        setPartModalVisible(false);
        onDataUpdate(); // Adat frissítése
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült hozzáadni az új alkatrészt: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  const handleCancel = () => {
    setProductModalVisible(false);
    setAccessoryModalVisible(false);
    setPartModalVisible(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleCancel();
    }
  };

  return (
    <div className="filter-container">
      <div className="selector-header" onClick={toggleDropdown}>
        <div className="selector-header-text">{activeItem.toUpperCase()}</div>
        <div className="selector-dropdown-icon">
          <img src={icon_filter} alt="Dropdown Icon" />
        </div>
      </div>

      {dropdownOpen && (
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => handleItemClick('Alkatrészek')}>Alkatrészek</div>
          <div className="dropdown-item" onClick={() => handleItemClick('Kiegészítők')}>Kiegészítők</div>
          <div className="dropdown-item" onClick={() => handleItemClick('Termékek')}>Termékek</div>
        </div>
      )}

      <div className="search-container">
        <div className="search-icon">
          <img src={icon_search} alt="Search Icon" />
        </div>
        <input
          type="text"
          placeholder="Tétel Keresése"
          className="search-input"
        />
      </div>

      <div className="category-list">
        {filterCategories[activeItem] && filterCategories[activeItem].map((category, index) => (
          <div key={index} className="category-item">
            <div className="category-header">
              <span className="category-text">{category.name}</span>
              <input
                type="checkbox"
                className="checkbox"
                checked={checkedCategories[category.name]?.checked || false}
                onChange={() => handleCategoryCheck(category.name)}
              />
            </div>

            {category.subcategories.map((sub, subIndex) => (
              <div key={subIndex} className="subcategory-item">
                <span className="subcategory-text">{sub}</span>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={checkedCategories[category.name]?.subcategories?.[sub] || false}
                  onChange={() => handleSubcategoryCheck(category.name, sub)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="new-item-button" onClick={handleNewItem}>
        
        <span className="new-item-text">ÚJ {activeItem.toUpperCase()}</span>
      </div>

      {isProductModalVisible && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <AddProductModal
              isOpen={isProductModalVisible}
              onSubmit={handleProductFormSubmit}
              onClose={handleCancel}
            />
          </div>
        </div>
      )}

      {isAccessoryModalVisible && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <AddAccessoryModal
              isOpen={isAccessoryModalVisible}
              onSubmit={handleAccessoryFormSubmit}
              onClose={handleCancel}
            />
          </div>
        </div>
      )}

      {isPartModalVisible && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <AddPartModal
              isOpen={isPartModalVisible}
              onSubmit={handlePartFormSubmit}
              onClose={handleCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryFilter;
