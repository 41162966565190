import React from 'react';
import NewProjectDataTable from '../../components/NewProjectDataTable/NewProjectDataTable.js';
import FormContainer from '../../components/FormContainer/FormContainer.js';

function NewProject() {
  return (
    <div>
      <div style={{ padding: '0px' }}>
        <FormContainer />
        <NewProjectDataTable />
      </div>
    </div>
  );
}

export default NewProject;
