import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import './EditAccessoryModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import FeeRow from '../FeeRow/FeeRow.js';

const EditAccessoryModal = ({ isOpen, accessoryId, onUpdate, onClose }) => {
  // Kiegészítők állapotai
  const [accessoryCategory, setAccessoryCategory] = useState([]);
  const [accessoryGroupName, setAccessoryGroupName] = useState([]);
  const [accessoryColor, setAccessoryColor] = useState([]);
  const [accessoryQuantityUnit, setAccessoryQuantityUnit] = useState([]);
  const [accessoryPackingUnit, setAccessoryPackingUnit] = useState([]);

  const [selectedAccessoryCategory, setSelectedAccessoryCategory] = useState('');
  const [selectedAccessoryGroupName, setSelectedAccessoryGroupName] = useState('');
  const [selectedAccessoryColor, setSelectedAccessoryColor] = useState('');
  const [selectedAccessoryQuantityUnit, setSelectedAccessoryQuantityUnit] = useState('');
  const [selectedAccessoryPackingUnit, setSelectedAccessoryPackingUnit] = useState('');

  const [accessoryName, setAccessoryName] = useState('');
  const [accessoryQuantity, setAccessoryQuantity] = useState(0);
  const [accessoryWeight, setAccessoryWeight] = useState(0);
  const [accessorySize, setAccessorySize] = useState('');
  const [accessoryInventoryPrice, setAccessoryInventoryPrice] = useState(0);

  // Napi bérleti díjak
  const [accessoryDay1Price, setAccessoryDay1Price] = useState(0);
  const [accessoryDay2Price, setAccessoryDay2Price] = useState(0);
  const [accessoryDay3Price, setAccessoryDay3Price] = useState(0);
  const [accessoryDay4Price, setAccessoryDay4Price] = useState(0);
  const [accessoryDay5Price, setAccessoryDay5Price] = useState(0);
  const [accessoryDay6Price, setAccessoryDay6Price] = useState(0);
  const [accessoryDay7Price, setAccessoryDay7Price] = useState(0);
  const [accessoryWeekendPrice, setAccessoryWeekendPrice] = useState(0);

  // Kiegészítő adatainak betöltése accessoryId alapján
  useEffect(() => {
    const fetchAccessoryDetails = async () => {
        try {
          const response = await fetch(`http://127.0.0.1:5000/api/accessories/${accessoryId}`);
          if (response.ok) {
            const accessoryData = await response.json();
            
            // Debugging - Log the data received from the backend
            console.log("Accessory data received from backend:", accessoryData);
      
            // Assign values based on the received data structure
            setAccessoryName(accessoryData.name);
            setSelectedAccessoryCategory(accessoryData.category);
            setSelectedAccessoryGroupName(accessoryData.group_name);
            setSelectedAccessoryColor(accessoryData.color);
            setSelectedAccessoryQuantityUnit(accessoryData.quantity_unit);
            setSelectedAccessoryPackingUnit(accessoryData.packing_unit);
            setAccessoryQuantity(accessoryData.quantity);
            setAccessoryWeight(accessoryData.weight);
            setAccessorySize(accessoryData.size);
            setAccessoryInventoryPrice(accessoryData.inventory_value);
      
            // Check if rental_prices exists in the data
            if (!accessoryData.rental_prices) {
              console.warn("Missing rental_prices in accessoryData:", accessoryData);
            } else {
              // Set rental prices if available
              setAccessoryDay1Price(accessoryData.rental_prices['1_day']);
              setAccessoryDay2Price(accessoryData.rental_prices['2_day']);
              setAccessoryDay3Price(accessoryData.rental_prices['3_day']);
              setAccessoryDay4Price(accessoryData.rental_prices['4_day']);
              setAccessoryDay5Price(accessoryData.rental_prices['5_day']);
              setAccessoryDay6Price(accessoryData.rental_prices['6_day']);
              setAccessoryDay7Price(accessoryData.rental_prices['7_day']);
              setAccessoryWeekendPrice(accessoryData.rental_prices['weekend']);
            }
      
          } else {
            Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő adatait.', 'error');
          }
        } catch (error) {
          Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
      };

    const fetchAccessoryCategories = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-category/list');
        if (response.ok) {
          const categories = await response.json();
          setAccessoryCategory(categories);
        }
      } catch (error) {
        console.error("Hiba a kiegészítő kategóriák lekérdezésekor", error);
      }
    };

    const fetchAccessoryColors = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-color/list');
        if (response.ok) {
          const colors = await response.json();
          setAccessoryColor(colors);
        }
      } catch (error) {
        console.error("Hiba a kiegészítő színek lekérdezésekor", error);
      }
    };

    const fetchAccessoryQuantityUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-quantity-unit/list');
        if (response.ok) {
          const units = await response.json();
          setAccessoryQuantityUnit(units);
        }
      } catch (error) {
        console.error("Hiba a mennyiségi egységek lekérdezésekor", error);
      }
    };

    const fetchAccessoryPackingUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-packing-unit/list');
        if (response.ok) {
          const packingUnits = await response.json();
          setAccessoryPackingUnit(packingUnits);
        }
      } catch (error) {
        console.error("Hiba a csomagolási egységek lekérdezésekor", error);
      }
    };

    if (accessoryId && isOpen) {
      fetchAccessoryDetails();
      fetchAccessoryCategories();
      fetchAccessoryColors();
      fetchAccessoryQuantityUnits();
      fetchAccessoryPackingUnits();
    }
  }, [accessoryId, isOpen]);

  const fetchAccessoryGroups = useCallback(async (categoryName) => {
    try {
      const response = await fetch(`http://127.0.0.1:5000/api/accessory-group/list?category_name=${categoryName}`);
      if (response.ok) {
        const groups = await response.json();
        setAccessoryGroupName(groups);
      }
    } catch (error) {
      console.error("Hiba a kiegészítő csoportok lekérdezésekor", error);
    }
  }, []);

  useEffect(() => {
    if (selectedAccessoryCategory) {
      fetchAccessoryGroups(selectedAccessoryCategory);
    }
  }, [selectedAccessoryCategory, fetchAccessoryGroups]);

  const handleSubmit = () => {
    // Validáció
    if (!accessoryName.trim()) {
        Swal.fire('Hiba', 'A kiegészítő neve nem lehet üres!', 'error');
        return;
    }
    if (!selectedAccessoryCategory || selectedAccessoryCategory === "" || selectedAccessoryCategory === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy kategóriát!', 'error');
        return;
    }
    if (!selectedAccessoryGroupName || selectedAccessoryGroupName === "" || selectedAccessoryGroupName === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy csoportot!', 'error');
        return;
    }
    if (!selectedAccessoryColor || selectedAccessoryColor === "" || selectedAccessoryColor === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy színt vagy anyagot!', 'error');
        return;
    }
    if (!selectedAccessoryQuantityUnit || selectedAccessoryQuantityUnit === "" || selectedAccessoryQuantityUnit === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy mennyiségi egységet!', 'error');
        return;
    }
    if (!selectedAccessoryPackingUnit || selectedAccessoryPackingUnit === "" || selectedAccessoryPackingUnit === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy csomagolási egységet!', 'error');
        return;
    }
    if (isNaN(accessoryQuantity) || accessoryQuantity <= 0) {
        Swal.fire('Hiba', 'A mennyiségnek pozitív számnak kell lennie!', 'error');
        return;
    }
    if (isNaN(accessoryWeight) || accessoryWeight <= 0) {
        Swal.fire('Hiba', 'A súlynak pozitív számnak kell lennie!', 'error');
        return;
    }
    if (!accessorySize.trim()) {
        Swal.fire('Hiba', 'A méret nem lehet üres!', 'error');
        return;
    }
    if (isNaN(accessoryInventoryPrice) || accessoryInventoryPrice <= 0) {
      Swal.fire('Hiba', 'A leltári értéknek pozitív számnak kell lennie!', 'error');
      return;
    }

    // Árak validációja
    const prices = [
        Number(accessoryDay1Price),
        Number(accessoryDay2Price),
        Number(accessoryDay3Price),
        Number(accessoryDay4Price),
        Number(accessoryDay5Price),
        Number(accessoryDay6Price),
        Number(accessoryDay7Price),
        Number(accessoryWeekendPrice)
    ];

    if (prices.some((price, index) => {
        console.log(`Checking price ${index + 1}:`, price, typeof price);
        if (isNaN(price)) {
            Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben csak számokat adhat meg!`, 'error');
            return true;
        }
        if (price <= 0) {
            Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben pozitív számot kell megadni!`, 'error');
            return true;
        }
        return false;
    })) {
        return;
    }

    // Minden adat helyes, továbbítjuk
    const accessoryData = {
        accessory_name: accessoryName,
        accessory_category: selectedAccessoryCategory,
        accessory_group_name: selectedAccessoryGroupName,
        accessory_color: selectedAccessoryColor,
        accessory_weight: Number(accessoryWeight),
        accessory_size: accessorySize,
        accessory_quantity: Number(accessoryQuantity),
        accessory_quantity_unit: selectedAccessoryQuantityUnit,
        accessory_packing_unit: selectedAccessoryPackingUnit,
        accessory_inventory_value: accessoryInventoryPrice,
        accessory_prices: {
          '1_day': accessoryDay1Price,
          '2_day': accessoryDay2Price,
          '3_day': accessoryDay3Price,
          '4_day': accessoryDay4Price,
          '5_day': accessoryDay5Price,
          '6_day': accessoryDay6Price,
          '7_day': accessoryDay7Price,
          'weekend': accessoryWeekendPrice
        }
      };

    // A accessoryData objektum elküldése
    console.log('Accessory data being submitted:', accessoryData);
    onUpdate(accessoryData);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-b-overlay">
      <div className="modal-b-content">
        <h2>Kiegészítő Szerkesztése</h2>
        {/* Kategória és csoport */}
        <Row
          elements={[
            { type: 'select', label: 'Kategória:', options: accessoryCategory, onInputChange: (e) => setSelectedAccessoryCategory(e.target.value), inputValue: selectedAccessoryCategory },
            { type: 'select', label: 'Csoport:', options: accessoryGroupName, onInputChange: (e) => setSelectedAccessoryGroupName(e.target.value), inputValue: selectedAccessoryGroupName }
          ]}
        />
        {/* Szín */}
        <Row
          elements={[
            { type: 'select', label: 'Szín:', options: accessoryColor, onInputChange: (e) => setSelectedAccessoryColor(e.target.value), inputValue: selectedAccessoryColor }
          ]}
        />
        {/* Kiegészítő neve */}
        <Row
          elements={[
            { type: 'labelField', label: 'Kiegészítő Neve:', placeholder: 'Kiegészítő Neve', onInputChange: (e) => setAccessoryName(e.target.value), inputValue: accessoryName }
          ]}
        />
        {/* Mennyiség és csomagolási egység */}
        <Row
          elements={[
            { type: 'select', label: 'Mennyiség Egysége:', options: accessoryQuantityUnit, onInputChange: (e) => setSelectedAccessoryQuantityUnit(e.target.value), inputValue: selectedAccessoryQuantityUnit },
            { type: 'select', label: 'Csomagolás Egysége:', options: accessoryPackingUnit, onInputChange: (e) => setSelectedAccessoryPackingUnit(e.target.value), inputValue: selectedAccessoryPackingUnit }
          ]}
        />
        {/* Mennyiség és súly */}
        <Row
          elements={[
            { type: 'labelField', label: 'Mennyiség:', placeholder: 'Mennyiség', onInputChange: (e) => setAccessoryQuantity(e.target.value), inputValue: accessoryQuantity },
            { type: 'labelField', label: 'Súly (kg):', placeholder: 'Súly', onInputChange: (e) => setAccessoryWeight(e.target.value), inputValue: accessoryWeight }
          ]}
        />
        {/* Méret és leltári érték */}
        <Row
          elements={[
            { type: 'labelField', label: 'Méret:', placeholder: 'Méret', onInputChange: (e) => setAccessorySize(e.target.value), inputValue: accessorySize },
            { type: 'labelField', label: 'Leltári Érték:', placeholder: 'Leltári Érték', onInputChange: (e) => setAccessoryInventoryPrice(e.target.value), inputValue: accessoryInventoryPrice }
          ]}
        />
        {/* Bérleti díjak */}
        <h3>Bérleti Díjak</h3>
        <FeeRow
          elements={[
            { type: 'labelField', label: '1 Nap (Ft):', onInputChange: (e) => setAccessoryDay1Price(e.target.value), inputValue: accessoryDay1Price },
            { type: 'labelField', label: '2 Nap (Ft):', onInputChange: (e) => setAccessoryDay2Price(e.target.value), inputValue: accessoryDay2Price },
            { type: 'labelField', label: '3 Nap (Ft):', onInputChange: (e) => setAccessoryDay3Price(e.target.value), inputValue: accessoryDay3Price },
            { type: 'labelField', label: '4 Nap (Ft):', onInputChange: (e) => setAccessoryDay4Price(e.target.value), inputValue: accessoryDay4Price }
          ]}
        />
        <FeeRow
          elements={[
            { type: 'labelField', label: '5 Nap (Ft):', onInputChange: (e) => setAccessoryDay5Price(e.target.value), inputValue: accessoryDay5Price },
            { type: 'labelField', label: '6 Nap (Ft):', onInputChange: (e) => setAccessoryDay6Price(e.target.value), inputValue: accessoryDay6Price },
            { type: 'labelField', label: '7 Nap (Ft):', onInputChange: (e) => setAccessoryDay7Price(e.target.value), inputValue: accessoryDay7Price },
            { type: 'labelField', label: 'Hétvége (Ft):', onInputChange: (e) => setAccessoryWeekendPrice(e.target.value), inputValue: accessoryWeekendPrice }
          ]}
        />
        {/* Gombok */}
        <div className="button-row">
          <TertiaryButton label="Mégse" onClick={onClose} />
          <PrimaryButton label="Mentés" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default EditAccessoryModal;
