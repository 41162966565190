import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

function Sidebar({ user }) {
  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img
          src={require('../../icons/colorent-logo.png')}
          alt="ColorRent Logo"
          className="sidebar-logo"
        />
      </div>
      <div className="user-info">
        <img
          src={require('../../icons/user-icon.png')}
          alt="User Icon"
          className="user-avatar"
        />
        <h3>{user.username}</h3>
        <p>{user.role}</p>
      </div>
      <div className="menu">
        <NavLink 
          to="/new-project" 
          className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
        >
          <img src={require('../../icons/new-project.png')} alt="New Project" />
          <span>Új Projekt</span>
        </NavLink>
        <NavLink 
          to="/projects" 
          className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
        >
          <img src={require('../../icons/new-project.png')} alt="Projects" />
          <span>Projektek</span>
        </NavLink>
        <NavLink 
          to="/schedule" 
          className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
        >
          <img src={require('../../icons/new-project.png')} alt="Schedule" />
          <span>Menetrend</span>
        </NavLink>
        <NavLink 
          to="/inventory-management" 
          className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
        >
          <img src={require('../../icons/new-project.png')} alt="Inventory" />
          <span>Készlet</span>
        </NavLink>
        <NavLink 
          to="/contact-list" 
          className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
        >
          <img src={require('../../icons/new-project.png')} alt="ContactList" />
          <span>Névjegyzék</span>
        </NavLink>
        {user.role === 'Owner' && (
          <NavLink 
            to="/admin" 
            className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}
          >
            <img src={require('../../icons/new-project.png')} alt="Admin" />
            <span>Admin</span>
          </NavLink>
        )}
      </div>
      <div className="logout">
        <button onClick={() => {
          localStorage.removeItem('token');
          window.dispatchEvent(new Event('storage')); // Notify about storage change
          window.location.href = '/login';
        }}>
          Kijelentkezés
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
