import React, { useState } from 'react';
import Row from '../Row/Row';
import './DynamicFormLogistic.css';
import AddingButton from '../Buttons/AddingButton';
import TertiaryButton from '../Buttons/TertiaryButton';
import DynamicFormLogisticExtra from './DynamicFormLogisticExtra';
import PrimaryButton from '../Buttons/PrimaryButton';

const DynamicFormLogistic = ({ formData, onInputChange, onSave, onCancel }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="dynamic-form">
      <div className="button-container">
        {!isFormVisible && (
          <AddingButton label="ÚJ TÉTEL Hozzáadása" onClick={toggleFormVisibility} />
        )}
      </div>
      {isFormVisible && (
        <div className="d-form-container visible">
          <div className="rows-container">
            {/* Row for select fields */}
            <Row
              elements={[
                {
                  type: 'select',
                  label: 'Munka jellege:',
                  options: ['Option 1', 'Option 2', 'Option 3'],
                  inputValue: formData.selectField,
                  onInputChange: (e) => onInputChange('selectField', e.target.value),
                  name: 'selectField',
                },
                {
                  type: 'select',
                  label: 'Autó típusa:',
                  options: ['Option 1', 'Option 2', 'Option 3'],
                  inputValue: formData.selectField,
                  onInputChange: (e) => onInputChange('selectField', e.target.value),
                  name: 'selectField',
                },
              ]}
            />
            {/* Row for additional select fields */}
            <Row
              elements={[
                {
                  type: 'select',
                  label: 'Emelőhátfalas:',
                  options: ['Igen', 'Nem'],
                  inputValue: formData.selectField,
                  onInputChange: (e) => onInputChange('selectField', e.target.value),
                  name: 'selectField',
                },
                {
                  type: 'select',
                  label: 'Behajtási engedély:',
                  options: ['Igen', 'Nem'],
                  inputValue: formData.selectField,
                  onInputChange: (e) => onInputChange('selectField', e.target.value),
                  name: 'selectField',
                },
              ]}
            />
             <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Találkozó cím:',
                  placeholder: 'Cím megadása',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            {/* Row for input fields */}
            <Row
              elements={[
                {
                  type: 'labelDatePicker',
                  label: 'Kezdés dátum | időpont:',
                  dateValue: startDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
                {
                  type: 'labelField',
                  label: 'Rendszám:',
                  placeholder: 'AAA132',
                  inputValue: formData.inputField,
                  onInputChange: (e) => onInputChange('inputField', e.target.value),
                  name: 'inputField',
                },
              ]}
            />
            <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Találkozó cím:',
                  placeholder: 'Cím megadása',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            <Row
              elements={[
                {
                  type: 'labelDatePicker',
                  label: 'Érkezés dátum | időpont:',
                  dateValue: arrivalDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
                {
                  type: 'labelDatePicker',
                  label: 'Végzés dátuma | időpont:',
                  dateValue: endDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
              ]}
            />
            <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Sofőr:',
                  placeholder: 'Név, sofőradatok',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
             <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Egyéb:',
                  placeholder: 'mit vesz fel, kontakt stb.',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            {/* Add more Row components here as needed */}
          </div>
          <DynamicFormLogisticExtra formData={formData} onInputChange={() => {}} />
          <div className="dynamic-button-row">
            <TertiaryButton label="MÉGSE" onClick={() => {
              setFormVisible(false);
              if (onCancel) onCancel();
            }} />
            <PrimaryButton label="HOZZÁADÁS" onClick={() => {
              if (onSave) onSave();
              setFormVisible(false);
            }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicFormLogistic;
