import React, { useState } from 'react';
import Pagination from '../Pagination/Pagination.js';
import './InventoryDataTable.css';
import iconEdit from '../../icons/icon_edit.png';
import EditPartModal from '../EditPartModal/EditPartModal.js';
import EditAccessoryModal from '../EditAccessoryModal/EditAccessoryModal.js';
import EditProductModal from '../EditProductModal/EditProductModal.js';
import Swal from 'sweetalert2';

const InventoryDataTable = ({ data, selectedCategory, onDataUpdate }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const itemsPerPage = 10;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const sortedData = data.slice().sort((a, b) => a.name.localeCompare(b.name));
  const currentData = sortedData.slice(firstItemIndex, lastItemIndex);

  const openEditModal = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const handlePartFormSubmit = async (partData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/editPart', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Alkatrészek',
          id: selectedItem.id,
          ...partData,
        }),
      });
  
      if (response.ok) {
        Swal.fire('Siker!', 'Az alkatrész sikeresen frissítve lett!', 'success');
        setIsEditModalOpen(false);
        onDataUpdate();
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült frissíteni az alkatrészt: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', `Nem sikerült kapcsolatba lépni a szerverrel: ${error}`, 'error');
    }
  };

  const handleAccessoryFormSubmit = async (accessoryData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/editAccessory', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Kiegészítők',
          id: selectedItem.id,
          ...accessoryData,
        }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'A kiegészítő sikeresen frissítve lett!', 'success');
        setIsEditModalOpen(false);
        onDataUpdate();
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült frissíteni a kiegészítőt: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  const handleProductFormSubmit = async (productData) => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/inventory/editProduct', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          category: 'Termékek',
          id: selectedItem.id,
          ...productData,
        }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'A termék sikeresen frissítve lett!', 'success');
        setIsEditModalOpen(false);
        onDataUpdate();
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', `Nem sikerült frissíteni a terméket: ${errorData.message}`, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  return (
    <div className="data-table-container">
      <div className="table-scroll-container">
        <table className="data-table">
          <colgroup>
            <col style={{ width: '200px' }} />
            <col style={{ width: '200px' }} />
            <col style={{ width: '300px' }} />
            <col style={{ width: '150px' }} />
            <col style={{ width: '150px' }} />
            <col style={{ width: '130px' }} />
            <col style={{ width: '70px' }} />
            
          </colgroup>
          <thead>
            <tr>
              <th>Kategória</th>
              <th>Csoport</th>
              <th>Termél Megnevezése</th>
              <th>Szín | Anyag | stb.</th>
              <th>Súly</th>
              <th>Méret</th>
              <th>Mennyiség</th>
              <th>Szerk</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              currentData.map((row) => (
                <tr key={row.id}>
                  <td>{row.name}</td>
                  <td>{row.category}</td>
                  <td>{row.group_name}</td>
                  <td>{row.color}</td>
                  <td>{row.weight}</td>
                  <td>{row.size}</td>
                  <td>{row.quantity}</td>
                  <td>
                    <button
                      onClick={() => openEditModal(row)}
                      style={{ cursor: 'pointer', border: 'none', background: 'none' }}
                    >
                      <img src={iconEdit} alt="Edit" style={{ height: '25px' }} />
                    </button>
                  </td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: 'center', height: '50px' }}>
                  Nincsenek adatok ebben a kategóriában.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <Pagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}

      {isEditModalOpen && selectedCategory === 'Alkatrészek' && (
        <EditPartModal
          isOpen={isEditModalOpen}
          partId={selectedItem?.id}
          onUpdate={handlePartFormSubmit}
          onClose={closeEditModal}
        />
      )}
      {isEditModalOpen && selectedCategory === 'Kiegészítők' && (
        <EditAccessoryModal
          isOpen={isEditModalOpen}
          accessoryId={selectedItem?.id}
          onUpdate={handleAccessoryFormSubmit}
          onClose={closeEditModal}
        />
      )}
      {isEditModalOpen && selectedCategory === 'Termékek' && (
        <EditProductModal
          isOpen={isEditModalOpen}
          productId={selectedItem?.id}
          onUpdate={handleProductFormSubmit}
          onClose={closeEditModal}
        />
      )}
    </div>
  );
};

export default InventoryDataTable;
