import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import NewProject from '../NewProject/NewProject.js';
import Projects from '../Projects/Projects.js';
import Schedule from '../Schedule/Schedule.js';
import ContactList from '../ContactList/ContactList.js'
import InventoryManagement from '../InventoryManagement/InventoryManagement.js';
import Admin from '../Admin/Admin.js';
import config from '../../config.js';

function Home() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${config.serverAddress}/api/protected`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser(data.logged_in_as);
        } else {
          throw new Error('Failed to authenticate');
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setError('Unable to verify user role. Please log in again.');
      }
    };

    fetchUserRole();
  }, []);

  if (error) {
    return (
      <div>
        <p>{error}</p>
        <button
          onClick={() => {
            localStorage.removeItem('token');
            window.location.href = '/login';
          }}
        >
          Go to Login
        </button>
      </div>
    );
  }

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="home">
      <Sidebar user={user} />
      <div className="content" style={{ marginLeft: '250px', padding: '20px' }}>
        <Routes>
          <Route path="/new-project" element={<NewProject />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/inventory-management" element={<InventoryManagement />} />
          <Route path="/contact-list" element={<ContactList />} />
          {user.role === 'Owner' && (
            <Route path="/admin" element={<Admin />} />
          )}
          <Route path="*" element={<Navigate to="/schedule" />} /> {/* Default route */}
        </Routes>
      </div>
    </div>
  );
}

export default Home;
