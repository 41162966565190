import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import './AddPartModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';

const ModalAddPart = ({ isOpen, onSubmit, onClose }) => {
  const [partCategory, setPartCategory] = useState([]);
  const [partFilteredGroupName, setPartFilteredGroupName] = useState([]);
  const [partColor, setPartColor] = useState([]);
  const [partQuantityUnit, setPartQuantityUnit] = useState([]);

  const [selectedPartCategory, setSelectedPartCategory] = useState('');
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectedPartColor, setSelectedPartColor] = useState('');
  const [selectedPartQuantityUnit, setSelectedPartQuantityUnit] = useState('');

  const [partName, setPartName] = useState('');
  const [partQuantity, setPartQuantity] = useState(0);
  const [partWeight, setPartWeight] = useState(0);
  const [partSize, setPartSize] = useState('');
  const [partWashingPrice, setPartWashingPrice] = useState(0);
  const [partInventoryPrice, setPartInventoryPrice] = useState(0); 

  // ÚJ ALKATRÉSZ KATEGÓRIA HOZZÁADÁSA
  const [newPartCategory, setNewPartCategory] = useState('');

  const handleCategorySubmit = async () => {
    // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
    const formattedPartCategory = newPartCategory.trim()
      ? (/[a-zA-Z]/.test(newPartCategory.charAt(0))
          ? newPartCategory.charAt(0).toUpperCase() + newPartCategory.slice(1).toLowerCase()
          : newPartCategory)
      : newPartCategory;

    if (!formattedPartCategory.trim()) {
      Swal.fire('Hiba', 'A kategória neve nem lehet üres!', 'error');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:5000/api/part-category/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ part_category_name: formattedPartCategory }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új kategória hozzáadásra került!', 'success');
        setNewPartCategory(''); // Reseteljük az input mezőt
      } else {
        const errorData = await response.json();
        if (errorData.message === 'Category already exists') {
          Swal.fire('Hiba!', 'Ez a kategória már létezik!', 'error');
        } else {
          Swal.fire('Hiba!', 'Nem sikerült hozzáadni a kategóriát!', 'error');
        }
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // ALKATRÉSZ KATEGÓRIÁK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-category/list');
        if (response.ok) {
          const categories = await response.json();
          setPartCategory(categories); // Alkatrész kategóriák beállítása
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni az alkatrész kategóriákat.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchCategories(); // Kategóriák lekérdezése a komponens betöltésekor
  }, [newPartCategory]);

  // ÚJ ALKATRÉSZ CSOPORT HOZZÁADÁSA
  const [newPartGroup, setNewPartGroup] = useState('');

  const handleGroupSubmit = async () => {
      const formattedGroup = newPartGroup.trim()
          ? (/[a-zA-Z]/.test(newPartGroup.charAt(0))
              ? newPartGroup.charAt(0).toUpperCase() + newPartGroup.slice(1).toLowerCase()
              : newPartGroup)
          : newPartGroup;

      if (!selectedPartCategory.trim()) {
          Swal.fire('Hiba', 'Kategória kiválasztása kötelező!', 'error');
          return;
      }

      if (!formattedGroup.trim()) {
          Swal.fire('Hiba', 'A csoport neve nem lehet üres!', 'error');
          return;
      }

      try {
          const response = await fetch('http://127.0.0.1:5000/api/part-group/add', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  part_group_name: formattedGroup,
                  part_selected_category_name: selectedPartCategory,
              }),
          });

          if (response.ok) {
              Swal.fire('Siker!', 'Az új csoport hozzáadásra került!', 'success');
              setNewPartGroup(''); // Reseteljük az input mezőt
              // Azonnal frissítjük a csoportok listáját
              fetchGroups();
          } else {
              const errorData = await response.json();
              if (errorData.message === 'Group already exists') {
                  Swal.fire('Hiba!', 'Ez a csoport már létezik!', 'error');
              } else {
                  Swal.fire('Hiba!', 'Nem sikerült hozzáadni a csoportot!', 'error');
              }
          }
      } catch (error) {
          Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
  };

  // ALKATRÉSZ CSOPORTOK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  const fetchGroups = useCallback(async () => {
      if (!selectedPartCategory.trim()) {
          Swal.fire('Hiba!', 'Kérjük, válasszon egy kategóriát!', 'error');
          return;
      }
      try {
          const response = await fetch(`http://127.0.0.1:5000/api/part-group/list?category_name=${selectedPartCategory}`);
          if (response.ok) {
              const groups = await response.json();
              setPartFilteredGroupName(groups);
          } else {
              Swal.fire('Hiba!', 'Nem sikerült lekérdezni az alkatrész csoportokat.', 'error');
          }
      } catch (error) {
          Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
  }, [selectedPartCategory]);

  useEffect(() => {
      // Alaphelyzetbe állítjuk a kiválasztott csoportot és a csoportok listáját, ha a kategória változik
      setSelectedGroupName('');  // Alapértelmezett állapot
      setPartFilteredGroupName([]); // Ürítjük a csoportokat

      // Lekérdezzük a csoportokat, ha van kiválasztott kategória
      if (selectedPartCategory.trim()) {
          fetchGroups();
      }
  }, [selectedPartCategory, fetchGroups]);

  // ÚJ ALKATRÉSZ SZÍN HOZZÁADÁSA
  const [newColor, setNewColor] = useState('');

  const handleColorSubmit = async () => {
    // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
    const formattedColor = newColor.trim()
      ? (/[a-zA-Z]/.test(newColor.charAt(0))
          ? newColor.charAt(0).toUpperCase() + newColor.slice(1).toLowerCase()
          : newColor)
      : newColor;

    if (!formattedColor.trim()) {
      Swal.fire('Hiba', 'A szín neve nem lehet üres!', 'error');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:5000/api/part-color/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ part_color_name: formattedColor }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új szín hozzáadásra került!', 'success');
        setNewColor(''); // Reseteljük az input mezőt
      } else {
        const errorData = await response.json();
        if (errorData.message === 'Color already exists') {
          Swal.fire('Hiba!', 'Ez a szín már létezik!', 'error');
        } else {
          Swal.fire('Hiba!', 'Nem sikerült hozzáadni a színt!', 'error');
        }
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };


  // ALKATRÉSZ SZÍNEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-color/list');
        if (response.ok) {
          const colors = await response.json();
          setPartColor(colors);
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni az alkatrész színeket.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchColors();
  }, [newColor]);

  // ÚJ ALKATRÉSZ MENNYISÉGI EGYSÉG HOZZÁADÁSA
  const [newQuantityUnit, setNewQuantityUnit] = useState('');

  const handleQuantityUnitSubmit = async () => {
    // A név formázása: minden betű nagybetűsre váltása
    const formattedQuantityUnit = newQuantityUnit.trim().toUpperCase();

    if (!formattedQuantityUnit) {
      Swal.fire('Hiba', 'A mennyiségi egység neve nem lehet üres!', 'error');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:5000/api/part-quantity-unit/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ part_quantity_unit_name: formattedQuantityUnit }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új mennyiségi egység hozzáadásra került!', 'success');
        setNewQuantityUnit(''); // Reseteljük az input mezőt
      } else {
        const errorData = await response.json();
        if (errorData.message === 'Quantity unit already exists') {
          Swal.fire('Hiba!', 'Ez a mennyiségi egység már létezik!', 'error');
        } else {
          Swal.fire('Hiba!', 'Nem sikerült hozzáadni a mennyiségi egységet!', 'error');
        }
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // ALKATRÉSZ MENNYISÉGI EGYSÉGEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchQuantityUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-quantity-unit/list');
        if (response.ok) {
          const quantityUnits = await response.json();
          setPartQuantityUnit(quantityUnits);
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni az alkatrész mennyiségi egységeket.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchQuantityUnits();
  }, [newQuantityUnit]);
  
  // MODAL MEGJELENÍTÉS ELLENŐRZÉSE
  if (!isOpen) {
    console.log('Modal is not open, returning null.');
    return null; // Ellenőrizzük, hogy meg kell-e jeleníteni a modal-t
  }

  const handleSubmit = () => {
    // Validáció
    if (!partName.trim()) {
      Swal.fire('Hiba', 'Az alkatrész neve nem lehet üres!', 'error');
      return;
    }
    if (!selectedPartCategory || selectedPartCategory === "" || selectedPartCategory === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy kategóriát!', 'error');
      return;
    }
    if (!selectedGroupName || selectedGroupName === "" || selectedGroupName === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy csoportot!', 'error');
      return;
    }
    if (!selectedPartColor || selectedPartColor === "" || selectedPartColor === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy színt vagy anyagot!', 'error');
      return;
    }
    if (!selectedPartQuantityUnit || selectedPartQuantityUnit === "" || selectedPartQuantityUnit === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy mennyiségi egységet!', 'error');
      return;
    }
    if (isNaN(partQuantity) || partQuantity <= 0) {
      Swal.fire('Hiba', 'A mennyiségnek pozitív számnak kell lennie!', 'error');
      return;
    }
    if (isNaN(partWeight) || partWeight <= 0) {
      Swal.fire('Hiba', 'A súlynak pozitív számnak kell lennie!', 'error');
      return;
    }
    if (!partSize.trim()) {
      Swal.fire('Hiba', 'A méret nem lehet üres!', 'error');
      return;
    }
    if (isNaN(partWashingPrice) || partWashingPrice < 0) {
      Swal.fire('Hiba', 'A mosatási díj nem lehet negatív szám!', 'error');
      return;
    }
    if (isNaN(partInventoryPrice) || partInventoryPrice <= 0) {
      Swal.fire('Hiba', 'A leltári értéknek pozitív számnak kell lennie!', 'error');
      return;
    }

    // Az összes adat helyes, folytatjuk az elküldést
    const productData = {
      part_name: partName,                          // Alkatrész neve
      part_category: selectedPartCategory,          // Alkatrész kategória
      part_group_name: selectedGroupName,           // Alkatrész csoport
      part_color: selectedPartColor,                // Alkatrész színe
      part_weight: partWeight,                      // Alkatrész súlya
      part_size: partSize,                          // Alkatrész mérete
      part_quantity: partQuantity,                  // Alkatrész mennyisége
      part_quantity_unit: selectedPartQuantityUnit, // Alkatrész mennyiségi egysége
      part_inventory_value: partInventoryPrice,     // Leltári érték
      part_wash_price: partWashingPrice             // Mosási díj (csomagolási egység)
    };

    // A productData objektum elküldése
    console.log('Product data being submitted:', productData);
    onSubmit(productData);
  };


  return (
    <div className="modal-b-overlay">
      <div className="modal-b-content">
        <h2>ÚJ ALKATRÉSZ HOZZÁADÁSA</h2>
        {/* Add new category + button, add new group + button */}
        <Row
          elements={[
            { 
              type: 'fieldButton', 
              placeholder: 'Új Kategória', 
              buttonLabel: 'ÚJ KATEGÓRIA', 
              onButtonClick: handleCategorySubmit,
              onInputChange: (e) => setNewPartCategory(e.target.value),
              inputValue: newPartCategory,
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Új Csoport', 
              buttonLabel: 'ÚJ CSOPORT',
              onButtonClick: handleGroupSubmit,
              onInputChange: (e) => setNewPartGroup(e.target.value),
              inputValue: newPartGroup,
            }
          ]}
        />
        {/* Choose category and group */}
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Kategória:', 
              options: partCategory, 
              onInputChange: (e) => setSelectedPartCategory(e.target.value), 
              inputValue: selectedPartCategory 
            },
            { 
              type: 'select', 
              label: 'Csoport:', 
              options: partFilteredGroupName, 
              onInputChange: (e) => setSelectedGroupName(e.target.value), 
              inputValue: selectedGroupName 
            }
          ]}
          style={{ marginBottom: '0px' }}  // Adding 50px margin-bottom to this specific row
        />        
        {/* Choose color or add new color */}
        <Row 
          elements={[
            { 
              type: 'select', 
              label: 'SZÍN | ANYAG | STB:', 
              options: partColor, 
              onInputChange: (e) => setSelectedPartColor(e.target.value), 
              inputValue: selectedPartColor 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Szín|Anyag Megnevezése', 
              buttonLabel: 'ÚJ SZÍN | ANYAG | STB.', 
              onButtonClick: handleColorSubmit,
              onInputChange: (e) => setNewColor(e.target.value),
              inputValue: newColor,
            }
          ]}
        />
        {/* Product Name */}
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Alkatrész Neve:', 
              placeholder: 'Alkatrész Neve', 
              onInputChange: (e) => setPartName(e.target.value),
              inputValue: partName
            },
          ]}
        />
        {/* Choose quantity unit or add new quantity unit */}
        <Row 
          elements={[
            { 
              type: 'select', 
              label: 'Mennyiség egysége:', 
              options: partQuantityUnit, 
              onInputChange: (e) => setSelectedPartQuantityUnit(e.target.value), 
              inputValue: selectedPartQuantityUnit 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Új Mennyiség Egység', 
              buttonLabel: 'ÚJ Egység', 
              onButtonClick: handleQuantityUnitSubmit,
              onInputChange: (e) => setNewQuantityUnit(e.target.value),
              inputValue: newQuantityUnit,
            }
          ]}
        />
        {/* Add quantity and weight */}
        <Row 
          elements={[
            { 
              type: 'labelField', 
              label: 'Mennyiség:', 
              placeholder: 'Mennyiség', 
              onInputChange: (e) => setPartQuantity(e.target.value),
              inputValue: partQuantity
            },
            { 
              type: 'labelField', 
              label: 'SÚLY (kg):', 
              placeholder: 'pl. 15', 
              onInputChange: (e) => setPartWeight(e.target.value),
              inputValue: partWeight
            }
          ]}
        />       
        {/* Add size and length x width */}
        <Row 
          elements={[
            { 
              type: 'labelField', 
              label: 'Méret:', 
              placeholder: 'Hossz x Szélesség', 
              onInputChange: (e) => setPartSize(e.target.value),
              inputValue: partSize
            }
          ]}
        />
        {/* Add washing and inventory price */}
        <Row 
          elements={[
            { 
              type: 'labelField', 
              label: 'Mosatási díj:', 
              placeholder: '2 000 Ft', 
              onInputChange: (e) => setPartWashingPrice(e.target.value),
              inputValue: partWashingPrice
            },
            { 
              type: 'labelField', 
              label: 'Leltári érték:', 
              placeholder: '65 000 Ft', 
              onInputChange: (e) => setPartInventoryPrice(e.target.value),
              inputValue: partInventoryPrice
            }
          ]}
        />
        {/* Adding primary and tertiary buttons in a row */}
        <div className="button-row">
          <TertiaryButton label="MÉGSE" onClick={onClose} /> {/* Bezárja a modalt */}
          <PrimaryButton label="LÉTREHOZÁS" onClick={handleSubmit} /> {/* Elküldi a formot */}
        </div>        
      </div>
    </div>
  );
};

export default ModalAddPart;
