import React, { useState, useEffect, useCallback } from 'react';
import InventoryDataTable from '../../components/InventoryDataTable/InventoryDataTable.js';
import InventoryFilter from '../../components/InventoryFilter/InventoryFilter.js';
import Swal from 'sweetalert2';
import './InventoryManagement.css';

function InventoryManagement() {
  const [selectedCategory, setSelectedCategory] = useState('Termékek');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedCategories, setCheckedCategories] = useState({});

  const fetchData = useCallback(async (category = selectedCategory) => {
    setLoading(true);
  
    try {
      const response = await fetch(`http://127.0.0.1:5000/api/inventory?category=${category}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      
      if (result.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Nincsenek adatok',
          text: 'A megadott kategóriában jelenleg nincsenek adatok.',
        });
        setData([]); // Üres adatkészlet beállítása
        setCheckedCategories({});
        return;
      }
  
      setData(result);
  
      const newCheckedCategories = {};
      result.forEach(item => {
        if (!newCheckedCategories[item.category]) {
          newCheckedCategories[item.category] = {
            checked: false,
            subcategories: {}
          };
        }
        if (!newCheckedCategories[item.category].subcategories[item.group_name]) {
          newCheckedCategories[item.category].subcategories[item.group_name] = false;
        }
      });
      setCheckedCategories(newCheckedCategories);
  
    } catch (error) {
      if (error.message.includes('Failed to fetch')) {
        Swal.fire({
          icon: 'error',
          title: 'Kapcsolati hiba',
          text: 'Nem sikerült kapcsolatba lépni a szerverrel. Ellenőrizd az internetkapcsolatot vagy a szerver elérhetőségét.',
          footer: error.message,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Hiba történt',
          text: 'Az adatok lekérésekor hiba történt.',
          footer: error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [selectedCategory]);
  
  useEffect(() => {
    setData([]);
    fetchData(selectedCategory);
  }, [selectedCategory, fetchData]);

  const onDataUpdate = () => {
    fetchData();
  };

  const handleCategoryChange = (category) => {
    if (!['Termékek', 'Alkatrészek', 'Kiegészítők'].includes(category)) {
      Swal.fire({
        icon: 'error',
        title: 'Érvénytelen kategória',
        text: 'Ismeretlen kategória lett kiválasztva. Kérlek válassz a következőkből: Termékek, Alkatrészek, Kiegészítők.',
      });
      return;
    }
    setSelectedCategory(category);
  };

  const handleFilterChange = (newCheckedCategories) => {
    setCheckedCategories(newCheckedCategories);
  };

  const filteredData = data.filter(item => {
    const categoryName = item.category;
    const subcategoryName = item.group_name;

    const hasCheckedCategories = Object.values(checkedCategories).some(
      category => category.checked || Object.values(category.subcategories || {}).some(subChecked => subChecked)
    );

    if (!hasCheckedCategories) {
      return true;
    }

    const category = checkedCategories[categoryName];
    const categoryChecked = category?.checked || false;
    const isSubcategoryChecked = category?.subcategories?.[subcategoryName] || false;

    return categoryChecked || isSubcategoryChecked;
  });

  return (
    <>
      <h2 style={{maxWidth: '1475px', width: '100%'}}>Készletkezelő</h2>
      <div className='inventory-management-container' style={{ padding: '0px' }}>
        {loading ? (
          <p>Betöltés...</p>
        ) : (
          <InventoryDataTable data={filteredData} selectedCategory={selectedCategory} onDataUpdate={onDataUpdate} />
        )}
        <InventoryFilter
          onCategoryChange={handleCategoryChange}
          onDataUpdate={onDataUpdate}
          onFilterChange={handleFilterChange}
        />
      </div>
      </>
  );
}

export default InventoryManagement;
