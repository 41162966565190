import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ScheduleDataTable from '../../components/ScheduleDataTable/ScheduleDataTables.js';

const localizer = momentLocalizer(moment);

function Schedule() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [eventsForSelectedDate, setEventsForSelectedDate] = useState([]);
  const [events, setEvents] = useState([]);

  const fetchEventsFromBackend = async () => {
    try {
      const response = await fetch('http://127.0.0.1:5000/api/events');
      if (response.ok) {
        const data = await response.json();
        const backendEvents = data.map(event => ({
          title: event.title,
          start: new Date(event.start),  // Helyi időt használunk
          end: new Date(event.end),      // Helyi időt használunk
        }));
        setEvents(backendEvents);
      } else {
        console.error('Failed to fetch events from backend.');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEventsFromBackend();
  }, []);

  const handleSelectSlot = ({ start }) => {
    setSelectedDate(start);
    const filteredEvents = events.filter(event => {
      const eventStart = moment(event.start).startOf('day');
      const eventEnd = moment(event.end).endOf('day');
      const selected = moment(start).startOf('day');
      
      return selected.isBetween(eventStart, eventEnd, null, '[]');
    });
    setEventsForSelectedDate(filteredEvents);
  };

  const handleNavigate = (date) => {
    setSelectedDate(date);
  };

console.log(`Events: ${JSON.stringify(events, null, 2)}`);
  

  return (
    <div>
      <div style={{ height: '500px', marginBottom: '20px' }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot}
          defaultView="month"
          onNavigate={handleNavigate}
          date={selectedDate}
          views={['month']}
        />
      </div>

      {selectedDate && (
        <div>
          <h3>{moment(selectedDate).format('YYYY MMMM DD')} - Események</h3>
          {eventsForSelectedDate.length > 0 ? (
            <ScheduleDataTable events={eventsForSelectedDate} />
          ) : (
            <p>Nincs esemény erre a napra.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Schedule;
