import React, { useState } from 'react';
import Row from '../Row/Row';
import AddingButton from '../Buttons/AddingButton';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import './DynamicFormHuman.css';

const DynamicFormHuman = ({ formData = {}, onInputChange, onSave }) => {
  const [isFormVisible, setFormVisible] = useState(false);

  // Initialize default values to prevent undefined errors
  const {
    jobType = '',
    totalPersonnel = '',
    driverCount = '',
    groupLeaderCount = '',
    startDate = null,
    endDate = null,
    meetingAddress = '',
    additionalMode = '',
  } = formData;

  // Toggle the form's visibility
  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="dynamic-form">
      <div className="button-container">
        {/* Toggle button for showing the form */}
        {!isFormVisible && (
          <AddingButton label="ÚJ TÉTEL Hozzáadása" onClick={toggleFormVisibility} />
        )}
      </div>

      {/* Only show the form when isFormVisible is true */}
      {isFormVisible && (
        <div className="d-form-container visible">
          <div className="rows-container">
            <Row
              elements={[
                {
                  type: 'select',
                  label: 'Munka jellege:',
                  options: ['Építés', 'Bontás', 'Raktári munka'],
                  inputValue: jobType,
                  onInputChange: (e) => onInputChange('jobType', e.target.value),
                  name: 'jobType',
                },
                {
                  type: 'selectInputNumber',
                  label: 'Össz létszám:',
                  placeholder: '1 - 20',
                  inputValue: totalPersonnel,
                  onInputChange: (e) => onInputChange('totalPersonnel', e.target.value),
                  max: 20,
                },
              ]}
            />

            <Row
              elements={[
                {
                  type: 'selectInputNumber',
                  label: 'Sofőr:',
                  placeholder: '1 - 5',
                  inputValue: driverCount,
                  onInputChange: (e) => onInputChange('driverCount', e.target.value),
                  max: 5,
                },
                {
                  type: 'selectInputNumber',
                  label: 'Csoport vezető:',
                  placeholder: '1 - 5',
                  inputValue: groupLeaderCount,
                  onInputChange: (e) => onInputChange('groupLeaderCount', e.target.value),
                  max: 5,
                },
              ]}
            />

            <Row
              elements={[
                {
                  type: 'labelDatePicker',
                  label: 'Kezdés dátum | időpont:',
                  dateValue: startDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
                {
                  type: 'labelDatePicker',
                  label: 'Végzés dátum | időpont:',
                  dateValue: endDate,
                  onDateChange: (date) => onInputChange('endDate', date),
                },
              ]}
            />

            <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Találkozó cím:',
                  placeholder: 'Cím megadása',
                  inputValue: meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />

            <Row
              elements={[
                {
                  type: 'select',
                  label: 'Egyéb:',
                  options: ['Személyautóval', 'Bagolyháton', 'Option 3'],
                  inputValue: additionalMode,
                  onInputChange: (e) => onInputChange('additionalMode', e.target.value),
                  name: 'additionalMode',
                },
              ]}
            />
          </div>

          <div className="dynamic-button-row">
            {/* Cancel button to close the form without saving */}
            <TertiaryButton label="MÉGSE" onClick={() => setFormVisible(false)} />
            {/* Save button to save the form data */}
            <PrimaryButton label="HOZZÁADÁS" onClick={() => {
              if (onSave) onSave(formData);
              setFormVisible(false);
            }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicFormHuman;
