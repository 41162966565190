import React, { useState, useEffect, useCallback  } from 'react';
import Swal from 'sweetalert2';
import './AddProductModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import FeeRow from '../FeeRow/FeeRow.js';

const AddProductModal = ({ isOpen, onSubmit, onClose }) => {
    const [productCategory, setProductCategory] = useState([]);
    const [productGroupName, setProductGroupName] = useState([]);
    const [productColor, setProductColor] = useState([]);
    const [productQuantityUnit, setProductQuantityUnit] = useState([]);
    const [productPackingUnit, setProductPackingUnit] = useState([]);

    const [selectedProductCategory, setSelectedProductCategory] = useState('');
    const [selectedProductGroupName, setSelectedProductGroupName] = useState('');
    const [selectedProductColor, setSelectedProductColor] = useState('');
    const [selectedProductQuantityUnit, setSelectedProductQuantityUnit] = useState('');
    const [selectedProductPackingUnit, setSelectedProductPackingUnit] = useState('');

    const [productName, setProductName] = useState('');
    const [productQuantity, setProductQuantity] = useState(0);
    const [productSize, setProductSize] = useState('');

    const [productDay1Price, setProductDay1Price] = useState(0);
    const [productDay2Price, setProductDay2Price] = useState(0);
    const [productDay3Price, setProductDay3Price] = useState(0);
    const [productDay4Price, setProductDay4Price] = useState(0);
    const [productDay5Price, setProductDay5Price] = useState(0);
    const [productDay6Price, setProductDay6Price] = useState(0);
    const [productDay7Price, setProductDay7Price] = useState(0);
    const [productWeekendPrice, setProductWeekendPrice] = useState(0);

    // Alkatrészek állapotai
    const [selectedPart1, setSelectedPart1] = useState('');
    const [selectedPart2, setSelectedPart2] = useState('');
    const [selectedPart3, setSelectedPart3] = useState('');
    const [selectedPart4, setSelectedPart4] = useState('');
    const [selectedPart5, setSelectedPart5] = useState('');
    const [selectedPart6, setSelectedPart6] = useState('');

    const [selectedPartQuantity1, setSelectedPartQuantity1] = useState(0);
    const [selectedPartQuantity2, setSelectedPartQuantity2] = useState(0);
    const [selectedPartQuantity3, setSelectedPartQuantity3] = useState(0);
    const [selectedPartQuantity4, setSelectedPartQuantity4] = useState(0);
    const [selectedPartQuantity5, setSelectedPartQuantity5] = useState(0);
    const [selectedPartQuantity6, setSelectedPartQuantity6] = useState(0);

    // A kiválasztott alkatrészek tárolása
    const [productParts, setProductParts] = useState([]);

    // Kiegészítők állapotai
    const [selectedAccessoryProduct1, setSelectedAccessoryProduct1] = useState('');
    const [selectedAccessoryProduct2, setSelectedAccessoryProduct2] = useState('');
    const [selectedAccessoryProduct3, setSelectedAccessoryProduct3] = useState('');
    const [selectedAccessoryProduct4, setSelectedAccessoryProduct4] = useState('');
    const [selectedAccessoryProduct5, setSelectedAccessoryProduct5] = useState('');
    const [selectedAccessoryProduct6, setSelectedAccessoryProduct6] = useState('');

    const [selectedAccessoryQuantity1, setSelectedAccessoryQuantity1] = useState(0);
    const [selectedAccessoryQuantity2, setSelectedAccessoryQuantity2] = useState(0);
    const [selectedAccessoryQuantity3, setSelectedAccessoryQuantity3] = useState(0);
    const [selectedAccessoryQuantity4, setSelectedAccessoryQuantity4] = useState(0);
    const [selectedAccessoryQuantity5, setSelectedAccessoryQuantity5] = useState(0);
    const [selectedAccessoryQuantity6, setSelectedAccessoryQuantity6] = useState(0);

    // Kiválasztott kiegészítők tárolása
    const [accessoryProducts, setAccessoryProducts] = useState([]);

    // Alkatrészek darabszámának frissítése kiválasztás szerint
    useEffect(() => {
    setSelectedPartQuantity1(selectedPart1 ? 1 : 0);
    }, [selectedPart1]);

    useEffect(() => {
    setSelectedPartQuantity2(selectedPart2 ? 1 : 0);
    }, [selectedPart2]);

    useEffect(() => {
    setSelectedPartQuantity3(selectedPart3 ? 1 : 0);
    }, [selectedPart3]);

    useEffect(() => {
    setSelectedPartQuantity4(selectedPart4 ? 1 : 0);
    }, [selectedPart4]);

    useEffect(() => {
    setSelectedPartQuantity5(selectedPart5 ? 1 : 0);
    }, [selectedPart5]);

    useEffect(() => {
    setSelectedPartQuantity6(selectedPart6 ? 1 : 0);
    }, [selectedPart6]);

    // Kiegészítők darabszámának frissítése kiválasztás szerint
    useEffect(() => {
    setSelectedAccessoryQuantity1(selectedAccessoryProduct1 ? 1 : 0);
    }, [selectedAccessoryProduct1]);

    useEffect(() => {
    setSelectedAccessoryQuantity2(selectedAccessoryProduct2 ? 1 : 0);
    }, [selectedAccessoryProduct2]);

    useEffect(() => {
    setSelectedAccessoryQuantity3(selectedAccessoryProduct3 ? 1 : 0);
    }, [selectedAccessoryProduct3]);

    useEffect(() => {
    setSelectedAccessoryQuantity4(selectedAccessoryProduct4 ? 1 : 0);
    }, [selectedAccessoryProduct4]);

    useEffect(() => {
    setSelectedAccessoryQuantity5(selectedAccessoryProduct5 ? 1 : 0);
    }, [selectedAccessoryProduct5]);

    useEffect(() => {
    setSelectedAccessoryQuantity6(selectedAccessoryProduct6 ? 1 : 0);
    }, [selectedAccessoryProduct6]);


    // ÚJ TERMÉK KATEGÓRIA HOZZÁADÁSA
    const [newProductCategory, setNewProductCategory] = useState('');

    const handleCategorySubmit = async () => {
        // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
        const formattedProductCategory = newProductCategory.trim()
        ? (/[a-zA-Z]/.test(newProductCategory.charAt(0))
            ? newProductCategory.charAt(0).toUpperCase() + newProductCategory.slice(1).toLowerCase()
            : newProductCategory)
        : newProductCategory;

        if (!formattedProductCategory.trim()) {
            Swal.fire('Hiba', 'A kategória neve nem lehet üres!', 'error');
            return;
        }

        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-category/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product_category_name: formattedProductCategory }),
            });

            if (response.ok) {
                Swal.fire('Siker!', 'Az új kategória hozzáadásra került!', 'success');
                setNewProductCategory(''); // Reseteljük az input mezőt
            } else {
                const errorData = await response.json();
                if (errorData.message === 'Category already exists') {
                    Swal.fire('Hiba!', 'Ez a kategória már létezik!', 'error');
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült hozzáadni a kategóriát!', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };

    // TERMÉK KATEGÓRIÁK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-category/list');
                if (response.ok) {
                    const categories = await response.json();
                    setProductCategory(categories); // Termék kategóriák beállítása
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült lekérdezni a termék kategóriákat.', 'error');
                }
            } catch (error) {
                Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
            }
        };

        fetchCategories(); // Kategóriák lekérdezése a komponens betöltésekor
    }, [newProductCategory]);

    // ÚJ TERMÉK CSOPORT HOZZÁADÁSA
    const [newProductGroup, setNewProductGroup] = useState('');

    const handleGroupSubmit = async () => {
        const formattedProductGroup = newProductGroup.trim()
            ? (/[a-zA-Z]/.test(newProductGroup.charAt(0))
                ? newProductGroup.charAt(0).toUpperCase() + newProductGroup.slice(1).toLowerCase()
                : newProductGroup)
            : newProductGroup;
    
        if (!selectedProductCategory.trim()) {
            Swal.fire('Hiba', 'Kategória kiválasztása kötelező!', 'error');
            return;
        }
    
        if (!formattedProductGroup.trim()) {
            Swal.fire('Hiba', 'A csoport neve nem lehet üres!', 'error');
            return;
        }
    
        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-group/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    product_group_name: formattedProductGroup,
                    product_selected_category_name: selectedProductCategory,
                }),
            });
    
            if (response.ok) {
                Swal.fire('Siker!', 'Az új csoport hozzáadásra került!', 'success');
                setNewProductGroup('');
                // Azonnal frissítjük a csoportok listáját
                fetchProductGroups();
            } else {
                const errorData = await response.json();
                if (errorData.message === 'Group already exists') {
                    Swal.fire('Hiba!', 'Ez a csoport már létezik!', 'error');
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült hozzáadni a csoportot!', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };
    
    // TERMÉK CSOPORTOK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    const fetchProductGroups = useCallback(async () => {
        if (!selectedProductCategory.trim()) {
            Swal.fire('Hiba!', 'Kérjük, válasszon egy kategóriát!', 'error');
            return;
        }
        try {
            const response = await fetch(`http://127.0.0.1:5000/api/product-group/list?category_name=${selectedProductCategory}`);
            if (response.ok) {
                const groups = await response.json();
                setProductGroupName(groups);
            } else {
                Swal.fire('Hiba!', 'Nem sikerült lekérdezni a termék csoportokat.', 'error');
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    }, [selectedProductCategory]);

    // Meghívás a komponens betöltésekor és a csoportok hozzáadásakor
    useEffect(() => {
        // Alaphelyzetbe állítjuk a kiválasztott csoportot és a csoportok listáját, ha a kategória változik
        setSelectedProductGroupName('');
        setProductGroupName([]);

        // Lekérdezzük a csoportokat, ha van kiválasztott kategória
        if (selectedProductCategory.trim()) {
            fetchProductGroups();
        }
    }, [selectedProductCategory, fetchProductGroups]);

    // ÚJ TERMÉK SZÍN HOZZÁADÁSA
    const [newProductColor, setNewProductColor] = useState('');

    const handleColorSubmit = async () => {
        // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
        const formattedProductColor = newProductColor.trim()
        ? (/[a-zA-Z]/.test(newProductColor.charAt(0))
            ? newProductColor.charAt(0).toUpperCase() + newProductColor.slice(1).toLowerCase()
            : newProductColor)
        : newProductColor;

        if (!formattedProductColor.trim()) {
            Swal.fire('Hiba', 'A szín neve nem lehet üres!', 'error');
            return;
        }

        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-color/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product_color_name: formattedProductColor }),
            });

            if (response.ok) {
                Swal.fire('Siker!', 'Az új szín hozzáadásra került!', 'success');
                setNewProductColor('');
            } else {
                const errorData = await response.json();
                if (errorData.message === 'Color already exists') {
                    Swal.fire('Hiba!', 'Ez a szín már létezik!', 'error');
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült hozzáadni a színt!', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };

    // TERMÉK SZÍNEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
        const fetchColors = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-color/list');
                if (response.ok) {
                    const colors = await response.json();
                    setProductColor(colors);
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült lekérdezni a termék színeket.', 'error');
                }
            } catch (error) {
                Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
            }
        };

    fetchColors();
    }, [newProductColor]);

    // ÚJ TERMÉK MENNYISÉGI EGYSÉG HOZZÁADÁSA
    const [newProductQuantityUnit, setNewProductQuantityUnit] = useState('');

    const handleQuantityUnitSubmit = async () => {
        // A név formázása: minden betű nagybetűsre váltása
        const formattedProductQuantityUnit = newProductQuantityUnit.trim().toUpperCase();

        if (!formattedProductQuantityUnit.trim()) {
            Swal.fire('Hiba', 'A mennyiségi egység neve nem lehet üres!', 'error');
            return;
        }

        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-quantity-unit/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product_quantity_unit_name: formattedProductQuantityUnit }),
            });

            if (response.ok) {
                Swal.fire('Siker!', 'Az új mennyiségi egység hozzáadásra került!', 'success');
                setNewProductQuantityUnit('');
            } else {
                const errorData = await response.json();
                if (errorData.message === 'Quantity unit already exists') {
                    Swal.fire('Hiba!', 'Ez a mennyiségi egység már létezik!', 'error');
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült hozzáadni a mennyiségi egységet!', 'error');
                }
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };

    // TERMÉK MENNYISÉGI EGYSÉGEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
    const fetchQuantityUnits = async () => {
        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-quantity-unit/list');
            if (response.ok) {
                const quantityUnits = await response.json();
                setProductQuantityUnit(quantityUnits);
            } else {
                Swal.fire('Hiba!', 'Nem sikerült lekérdezni a termék mennyiségi egységeket.', 'error');
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };

    fetchQuantityUnits();
    }, [newProductQuantityUnit]);

    // ÚJ TERMÉK CSOMAGOLÁSI EGYSÉG HOZZÁADÁSA
    const [newPackingUnit, setNewPackingUnit] = useState('');

    const handlePackingUnitSubmit = async () => {
        if (!newPackingUnit.trim()) {
            Swal.fire('Hiba', 'A csomagolási egység neve nem lehet üres!', 'error');
            return;
        }

        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-packing-unit/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ product_packing_unit_name: newPackingUnit }),
            });

            if (response.ok) {
                Swal.fire('Siker!', 'Az új csomagolási egység hozzáadásra került!', 'success');
                setNewPackingUnit('');
            } else {
                const errorData = await response.json();
                Swal.fire('Hiba!', errorData.message, 'error');
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
    };

    // ÚJ TERMÉK CSOMAGOLÁSI EGYSÉGEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
        const fetchPackingUnits = async () => {
        try {
            const response = await fetch('http://127.0.0.1:5000/api/product-packing-unit/list');
            if (response.ok) {
                const packingUnits = await response.json();
                setProductPackingUnit(packingUnits); // Termék csomagolási egységek beállítása
            } else {
                Swal.fire('Hiba!', 'Nem sikerült lekérdezni a csomagolási egységeket.', 'error');
            }
        } catch (error) {
            Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
        }
        };

        fetchPackingUnits(); // Csomagolási egységek lekérdezése a komponens betöltésekor
    }, [newPackingUnit]);

    // TERMÉK ALKATRÉSZEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
        const fetchProductParts = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-parts/list');
                if (response.ok) {
                    const parts = await response.json();
                    // Itt feltételezzük, hogy a backend most már id és name mezőket küld
                    const formattedParts = parts.map((part) => ({
                        id: part.id,  // ID és name mezők beállítása
                        name: part.name
                    }));
                    setProductParts(formattedParts);  // Beállítjuk a termék alkatrészeket
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült lekérdezni a termék alkatrészeket.', 'error');
                }
            } catch (error) {
                Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
            }
        };
        fetchProductParts();
    }, []);

    // TERMÉKHEZ RENDELT KIEGÉSZÍTŐK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
    useEffect(() => {
        const fetchAccessoryParts = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/accessory-products/list');
                if (response.ok) {
                    const accessories = await response.json();
                    // Feltételezzük, hogy a backend most már id és name mezőket küld
                    const formattedAccessories = accessories.map((accessory) => ({
                        id: accessory.id,  // ID és name mezők beállítása
                        name: accessory.name
                    }));
                    setAccessoryProducts(formattedAccessories);  // Beállítjuk a kiegészítő termékeket
                } else {
                    Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő alkatrészeket.', 'error');
                }
            } catch (error) {
                Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
            }
        };
        fetchAccessoryParts();
    }, []);

    const handleSubmit = () => {
        // Validáció
        if (!productName.trim()) {
            Swal.fire('Hiba', 'A termék neve nem lehet üres!', 'error');
            return;
        }
        if (!selectedProductCategory || selectedProductCategory === "" || selectedProductCategory === "Kérem, válasszon!") {
            Swal.fire('Hiba', 'Válassz egy kategóriát!', 'error');
            return;
        }
        if (!selectedProductGroupName || selectedProductGroupName === "" || selectedProductGroupName === "Kérem, válasszon!") {
            Swal.fire('Hiba', 'Válassz egy csoportot!', 'error');
            return;
        }
        if (!selectedProductColor || selectedProductColor === "" || selectedProductColor === "Kérem, válasszon!") {
            Swal.fire('Hiba', 'Válassz egy színt vagy anyagot!', 'error');
            return;
        }
        if (!selectedProductQuantityUnit || selectedProductQuantityUnit === "" || selectedProductQuantityUnit === "Kérem, válasszon!") {
            Swal.fire('Hiba', 'Válassz egy mennyiségi egységet!', 'error');
            return;
        }
        if (!selectedProductPackingUnit || selectedProductPackingUnit === "" || selectedProductPackingUnit === "Kérem, válasszon!") {
            Swal.fire('Hiba', 'Válassz egy csomagolási egységet!', 'error');
            return;
        }
        if (isNaN(productQuantity) || productQuantity <= 0) {
            Swal.fire('Hiba', 'A mennyiségnek pozitív számnak kell lennie!', 'error');
            return;
        }
        if (!productSize.trim()) {
            Swal.fire('Hiba', 'A méret nem lehet üres!', 'error');
            return;
        }
    
        // Árak validációja
        const prices = [
            Number(productDay1Price),
            Number(productDay2Price),
            Number(productDay3Price),
            Number(productDay4Price),
            Number(productDay5Price),
            Number(productDay6Price),
            Number(productDay7Price),
            Number(productWeekendPrice)
        ];
    
        if (prices.some((price, index) => {
            if (isNaN(price)) {
                Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben csak számokat adhat meg!`, 'error');
                return true;
            }
            if (price <= 0) {
                Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben pozitív számot kell megadni!`, 'error');
                return true;
            }
            return false;
        })) {
            return;
        }
    
        // Ellenőrizzük, hogy van-e legalább egy alkatrész
        const hasAtLeastOnePart = [
            selectedPart1, selectedPart2, selectedPart3, selectedPart4, selectedPart5, selectedPart6
        ].some(part => part && part !== "Kérem, válasszon!");
    
        if (!hasAtLeastOnePart) {
            Swal.fire('Hiba', 'A terméknek legalább egy alkatrészből kell állnia!', 'error');
            return;
        }

        console.log(`Selected part 1 name: ${selectedPart1}, Selected part 1 quantity:${selectedPartQuantity1}`);

        console.log(`Selected part 2 name: ${selectedPart2}, Selected part 2 quantity:${selectedPartQuantity2}`);
        
    
        // Minden adat helyes, továbbítjuk
        const productData = {
            product_name: productName,
            product_category: selectedProductCategory,
            product_group_name: selectedProductGroupName,
            product_color: selectedProductColor,
            product_size: productSize,
            product_quantity: Number(productQuantity),
            product_quantity_unit: selectedProductQuantityUnit,
            product_packing_unit: selectedProductPackingUnit,
            product_prices: {
                day1: prices[0],
                day2: prices[1],
                day3: prices[2],
                day4: prices[3],
                day5: prices[4],
                day6: prices[5],
                day7: prices[6],
                weekend: prices[7]
            },
            product_parts: [
                { part: selectedPart1, quantity: selectedPartQuantity1 },
                { part: selectedPart2, quantity: selectedPartQuantity2 },
                { part: selectedPart3, quantity: selectedPartQuantity3 },
                { part: selectedPart4, quantity: selectedPartQuantity4 },
                { part: selectedPart5, quantity: selectedPartQuantity5 },
                { part: selectedPart6, quantity: selectedPartQuantity6 }
            ].filter(partObj => partObj.part && partObj.part !== "Kérem, válasszon!"), // Csak kiválasztott alkatrészek
            accessory_products: [
                { accessory: selectedAccessoryProduct1, quantity: selectedAccessoryQuantity1 },
                { accessory: selectedAccessoryProduct2, quantity: selectedAccessoryQuantity2 },
                { accessory: selectedAccessoryProduct3, quantity: selectedAccessoryQuantity3 },
                { accessory: selectedAccessoryProduct4, quantity: selectedAccessoryQuantity4 },
                { accessory: selectedAccessoryProduct5, quantity: selectedAccessoryQuantity5 },
                { accessory: selectedAccessoryProduct6, quantity: selectedAccessoryQuantity6 }
            ].filter(accessoryObj => accessoryObj.accessory && accessoryObj.accessory !== "Kérem, válasszon!") // Csak kiválasztott kiegészítők
        };
    
        // A productData objektum elküldése
        console.log('Product data being submitted:', productData);
        onSubmit(productData);
    };    

    // Modal megjelenítésének ellenőrzése
    if (!isOpen) {
        return null;
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
            <h2 className='modal-title'>ÚJ TERMÉK HOZZÁADÁSA</h2> 
            <div className="scrollable-content">
                <Row
                    elements={[
                    { 
                        type: 'fieldButton', 
                        placeholder: 'Új Kategória', 
                        buttonLabel: 'ÚJ KATEGÓRIA', 
                        onButtonClick: handleCategorySubmit,
                        onInputChange: (e) => setNewProductCategory(e.target.value),
                        inputValue: newProductCategory 
                    },
                    { 
                        type: 'fieldButton', 
                        placeholder: 'Új Csoport', 
                        buttonLabel: 'ÚJ CSOPORT', 
                        onButtonClick: handleGroupSubmit,
                        onInputChange: (e) => setNewProductGroup(e.target.value),
                        inputValue: newProductGroup 
                    }
                    ]}
                />  
                {/* Row 2: Choose Category And Group */}
                <Row
                    elements={[
                    { 
                        type: 'select', 
                        label: 'Kategória:', 
                        options: productCategory, 
                        onInputChange: (e) => setSelectedProductCategory(e.target.value), 
                        inputValue: selectedProductCategory 
                    },
                    { 
                        type: 'select', 
                        label: 'Csoport:', 
                        options: productGroupName, 
                        onInputChange: (e) => setSelectedProductGroupName(e.target.value), 
                        inputValue: selectedProductGroupName 
                    }
                    ]}
                />  
                {/* Row 3: Product Name */}
                <Row
                    elements={[
                    { 
                        type: 'labelField', 
                        label: 'Termék Neve:', 
                        placeholder: 'Termék Neve', 
                        onInputChange: (e) => setProductName(e.target.value),
                        inputValue: productName 
                    }
                    ]}
                />  
                {/* Row 4: Color, Material Designation */}
                <Row
                    elements={[
                    { 
                    type: 'select', 
                    label: 'SZÍN | ANYAG | STB:', 
                    options: productColor, 
                    onInputChange: (e) => setSelectedProductColor(e.target.value), 
                    inputValue: selectedProductColor 
                    },
                    { 
                        type: 'fieldButton', 
                        placeholder: 'Szín|Anyag Megnevezése', 
                        buttonLabel: 'ÚJ SZÍN | ANYAG | STB.', 
                        onButtonClick: handleColorSubmit,
                        onInputChange: (e) => setNewProductColor(e.target.value),
                        inputValue: newProductColor 
                    }
                    ]}
                />  
                {/* Row 5: Select + Label, Field + Button */}
                <Row
                    elements={[
                    { 
                        type: 'select', 
                        label: 'Mennyiség egysége:', 
                        options: productQuantityUnit, 
                        onInputChange: (e) => setSelectedProductQuantityUnit(e.target.value), 
                        inputValue: selectedProductQuantityUnit 
                    },
                    { 
                        type: 'fieldButton', 
                        placeholder: 'Mennyiség egység', 
                        buttonLabel: 'ÚJ egység', 
                        onButtonClick: handleQuantityUnitSubmit,
                        onInputChange: (e) => setNewProductQuantityUnit(e.target.value),
                        inputValue: newProductQuantityUnit 
                    }
                    ]}
                />  
                {/* Row 6: Quantity */}
                <Row
                    elements={[
                    { 
                        type: 'labelField', 
                        label: 'Mennyiség:', 
                        placeholder: 'Mennyiség', 
                        onInputChange: (e) => setProductQuantity(e.target.value),
                        inputValue: productQuantity 
                    }
                    ]}
                />  
                {/* Row 7: Packing Unit */}
                <Row
                    elements={[
                    { 
                        type: 'select', 
                        label: 'Csomagolás egysége:', 
                        options: productPackingUnit, 
                        onInputChange: (e) => setSelectedProductPackingUnit(e.target.value), 
                        inputValue: selectedProductPackingUnit 
                    },
                    { 
                        type: 'fieldButton', 
                        placeholder: 'Egység megnevezése', 
                        buttonLabel: 'Új Csom. Egység', 
                        onButtonClick: handlePackingUnitSubmit,
                        onInputChange: (e) => setNewPackingUnit(e.target.value),
                        inputValue: newPackingUnit 
                    }
                    ]}
                />  
                {/* Row 8: Size */}
                <Row
                    elements={[
                    { 
                        type: 'labelField', 
                        label: 'Méret:', 
                        placeholder: 'Hossz x Szélesség', 
                        onInputChange: (e) => setProductSize(e.target.value),
                        inputValue: productSize 
                    }
                    ]}
                />  
                {/* Ár Hozzáadása */}
                <h3>ÁR HOZZÁADÁSA</h3>
                <FeeRow
                    elements={[
                    { type: 'labelField', label: '1 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay1Price(e.target.value), inputValue: productDay1Price },
                    { type: 'labelField', label: '2 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay2Price(e.target.value), inputValue: productDay2Price },
                    { type: 'labelField', label: '3 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay3Price(e.target.value), inputValue: productDay3Price },
                    { type: 'labelField', label: '4 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay4Price(e.target.value), inputValue: productDay4Price }
                    ]}
                />  
                <FeeRow
                    elements={[
                    { type: 'labelField', label: '5 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay5Price(e.target.value), inputValue: productDay5Price },
                    { type: 'labelField', label: '6 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay6Price(e.target.value), inputValue: productDay6Price },
                    { type: 'labelField', label: '7 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setProductDay7Price(e.target.value), inputValue: productDay7Price },
                    { type: 'labelField', label: 'P-SZ-V:', placeholder: '10 00', onInputChange: (e) => setProductWeekendPrice(e.target.value), inputValue: productWeekendPrice }
                    ]}
                />  
                {/* Alkatrész Hozzárendelés */}
                <h3>ALAKTRÉSZ HOZZÁRENDELÉS</h3>
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '1# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart1(e.target.value), 
                    inputValue: selectedPart1,
                    quantity: selectedPartQuantity1, 
                    onQuantityChange: (e) => setSelectedPartQuantity1(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '2# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart2(e.target.value), 
                    inputValue: selectedPart2,
                    quantity: selectedPartQuantity2, 
                    onQuantityChange: (e) => setSelectedPartQuantity2(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '3# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart3(e.target.value), 
                    inputValue: selectedPart3,
                    quantity: selectedPartQuantity3, 
                    onQuantityChange: (e) => setSelectedPartQuantity3(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '4# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart4(e.target.value), 
                    inputValue: selectedPart4,
                    quantity: selectedPartQuantity4, 
                    onQuantityChange: (e) => setSelectedPartQuantity4(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '5# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart5(e.target.value), 
                    inputValue: selectedPart5,
                    quantity: selectedPartQuantity5, 
                    onQuantityChange: (e) => setSelectedPartQuantity5(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '6# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart6(e.target.value), 
                    inputValue: selectedPart6,
                    quantity: selectedPartQuantity6, 
                    onQuantityChange: (e) => setSelectedPartQuantity6(Number(e.target.value)) 
                    }
                ]}
                />

                {/* Termékhez Kiegészítő Hozzárendelés */}
                <h3>TERMÉKHEZ KIEGÉSZÍTŐ HOZZÁSRENDELÉS</h3>
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '1# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct1(e.target.value), 
                    inputValue: selectedAccessoryProduct1,
                    quantity: selectedAccessoryQuantity1, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity1(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '2# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct2(e.target.value), 
                    inputValue: selectedAccessoryProduct2,
                    quantity: selectedAccessoryQuantity2, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity2(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '3# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct3(e.target.value), 
                    inputValue: selectedAccessoryProduct3,
                    quantity: selectedAccessoryQuantity3, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity3(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '4# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct4(e.target.value), 
                    inputValue: selectedAccessoryProduct4,
                    quantity: selectedAccessoryQuantity4, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity4(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '5# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct5(e.target.value), 
                    inputValue: selectedAccessoryProduct5,
                    quantity: selectedAccessoryQuantity5, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity5(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '6# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct6(e.target.value), 
                    inputValue: selectedAccessoryProduct6,
                    quantity: selectedAccessoryQuantity6, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity6(Number(e.target.value)) 
                    }
                ]}
                />
                </div>
                {/* Gombok */}
                <div className="button-row">
                    <TertiaryButton label="MÉGSE" onClick={onClose} />
                    <PrimaryButton label="LÉTREHOZÁS" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );  
};

export default AddProductModal;
