import React from 'react';
import iconEdit from '../../../icons/icon_edit.png';
import iconDetails from '../../../icons/icon_details.png';
import iconDelete from '../../../icons/icon_delete.png';
import DynamicFormHuman from '../../DynamicFormHuman/DynamicFormHuman';
import DynamicFormAsset from '../../DynamicFormAsset/DynamicFormAsset';

const AssetsSection = ({ data, onAdd, onDelete }) => {
  // Initialize formData with the required fields
  const initialFormData = {
    jobType: '',
    totalPersonnel: '',
    driverCount: '',
    groupLeaderCount: '',
    startDate: null,
    endDate: null,
    meetingAddress: '',
    additionalMode: '',
  };

  return (
    <div className="section-container">
      <div className="section-header section-eszkozok">ESZKÖZÖK</div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Tétel Megnevezése</th>
            <th>Hossza (nap)</th>
            <th>Helyszín</th>
            <th>Megrendelő</th>
            <th>Még vm</th>
            <th>Bármi</th>
            <th>Szerk</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.name}</td>
              <td>{row.duration}</td>
              <td>{row.location}</td>
              <td>{row.client}</td>
              <td>{row.other}</td>
              <td>{row.any}</td>
              <td>
                <img src={iconEdit} alt="Edit" style={{ cursor: 'pointer', height: '25px' }} />
              </td>
              <td>
                <img src={iconDetails} alt="Details" style={{ cursor: 'pointer', height: '25px' }} />
              </td>
              <td>
                <img src={iconDelete} alt="Delete" style={{ cursor: 'pointer', height: '25px' }} onClick={() => onDelete(row.id)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DynamicFormAsset formData={initialFormData} onInputChange={() => {}} />
    </div>
  );
};

export default AssetsSection;
