import React from 'react';
import './Pagination.css';

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrev = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div className="pagination-container">
      <span className="pagination-info">
        {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(currentPage * itemsPerPage, totalItems)} / ${totalItems}`}
      </span>
      <div className="pagination-buttons">
        <button onClick={handlePrev} disabled={currentPage === 1} className="pagination-button">
          &lt;
        </button>
        <button onClick={handleNext} disabled={currentPage === totalPages} className="pagination-button">
          &gt;
        </button>
      </div>
    </div>
  );
  
};
export default Pagination;
