// TextDisplay.js
import React from 'react';
import './TextDisplay.css';  // Import the new CSS file

const TextDisplay = ({ text, placeholder }) => {
  return (
    <div className={`text-display-field ${text ? 'filled' : ''}`}>
      {text || <span className="placeholder">{placeholder}</span>}
    </div>
  );
};

export default TextDisplay;
