import React from 'react';
import SourceSwitch from '../../components/SourceSwitch/SourceSwitch.js';
import './ContactList.css';

function ContactList() {
  return (
    <div className="contact-list-container">
      <h2>Névjegyzék</h2>
      <SourceSwitch />
      <div className="contact-list-table">
        {/* Itt helyezheted el a táblázatot */}
        <table>
          {/* Táblázat tartalma */}
        </table>
      </div>
    </div>
  );
}

export default ContactList;
