import React, { useState } from 'react';
import Row from '../Row/Row';
import AddingButton from '../Buttons/AddingButton';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import './DynamicFormAsset.css';
import DynamicFormAssetExta from './DynamicFormAssetExta'
import DynamicFormHuman from '../DynamicFormHuman/DynamicFormHuman'

const ComponentA = () => <div>Component A Content</div>;

const DynamicFormAsset = ({ formData = {}, onInputChange, onSave }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [isComponentA, setIsComponentA] = useState(true);

  const handleToggle = () => {
    setIsComponentA(!isComponentA);
  };
  // Static options for dropdowns
  const productCategory = ['Category 1', 'Category 2', 'Category 3'];
  const productGroupName = ['Group A', 'Group B', 'Group C'];
  const productColor = ['Red', 'Blue', 'Green'];



  // State initializations for form fields
  const [newProductCategory, setNewProductCategory] = useState('');
  const [newProductGroup, setNewProductGroup] = useState('');
  const [selectedProductCategory, setSelectedProductCategory] = useState('');
  const [selectedProductGroupName, setSelectedProductGroupName] = useState('');
  const [productName, setProductName] = useState('');
  const [selectedProductColor, setSelectedProductColor] = useState('');
  const [newProductColor, setNewProductColor] = useState('');
  const [selectedProductQuantityUnit, setSelectedProductQuantityUnit] = useState('');
  const [newProductQuantityUnit, setNewProductQuantityUnit] = useState('');
  const [productQuantity, setProductQuantity] = useState('');
  const [productWeight, setProductWeight] = useState('');
  const [selectedProductPackingUnit, setSelectedProductPackingUnit] = useState('');
  const [newPackingUnit, setNewPackingUnit] = useState('');
  const [productSize, setProductSize] = useState('');
  const [productDay1Price, setProductDay1Price] = useState('');
  const [productDay2Price, setProductDay2Price] = useState('');
  const [productDay3Price, setProductDay3Price] = useState('');
  const [productDay4Price, setProductDay4Price] = useState('');
  const [productDay5Price, setProductDay5Price] = useState('');
  const [productDay6Price, setProductDay6Price] = useState('');
  const [productDay7Price, setProductDay7Price] = useState('');
  const [productWeekendPrice, setProductWeekendPrice] = useState('');
  const [selectedPart1, setSelectedPart1] = useState('');
  const [selectedPart2, setSelectedPart2] = useState('');
  const [selectedPart3, setSelectedPart3] = useState('');
  const [selectedPart4, setSelectedPart4] = useState('');
  const [selectedPart5, setSelectedPart5] = useState('');
  const [selectedPart6, setSelectedPart6] = useState('');
  const [selectedAccessoryProduct1, setSelectedAccessoryProduct1] = useState('');
  const [selectedAccessoryProduct2, setSelectedAccessoryProduct2] = useState('');
  const [selectedAccessoryProduct3, setSelectedAccessoryProduct3] = useState('');
  const [selectedAccessoryProduct4, setSelectedAccessoryProduct4] = useState('');
  const [selectedAccessoryProduct5, setSelectedAccessoryProduct5] = useState('');
  const [selectedAccessoryProduct6, setSelectedAccessoryProduct6] = useState('');

  // Handler functions (placeholders)
  const handleCategorySubmit = () => { /* Handle category submission */ };
  const handleGroupSubmit = () => { /* Handle group submission */ };
  const handleColorSubmit = () => { /* Handle color submission */ };
  const handleQuantityUnitSubmit = () => { /* Handle quantity unit submission */ };
  const handlePackingUnitSubmit = () => { /* Handle packing unit submission */ };

  // Toggle the form's visibility
  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="dynamic-form">
      <div className="button-container">
        {!isFormVisible && (
          <AddingButton label="ÚJ TÉTEL Hozzáadása" onClick={toggleFormVisibility} />
        )}
      </div>

      {isFormVisible && (
        <div className="d-form-container visible">
          <div className="rows-container">
      {/* Render the component based on the switch state */}    
            <Row
                elements={[
                  { 
                      type: 'select', 
                      label: 'Kategória:', 
                      options: productCategory, 
                      onInputChange: (e) => setSelectedProductCategory(e.target.value), 
                      inputValue: selectedProductCategory 
                  },
                  { 
                      type: 'select', 
                      label: 'Csoport:', 
                      options: productGroupName, 
                      onInputChange: (e) => setSelectedProductGroupName(e.target.value), 
                      inputValue: selectedProductGroupName 
                  }
                ]}
            />  
            <Row
                elements={[
                  { 
                    type: 'select', 
                    label: 'Termék megnevezés:', 
                    options: productGroupName, 
                    onInputChange: (e) => setSelectedProductGroupName(e.target.value), 
                    inputValue: selectedProductGroupName 
                },
                { 
                  type: 'select', 
                  label: 'SZÍN | ANYAG | STB:', 
                  options: productColor, 
                  onInputChange: (e) => setSelectedProductColor(e.target.value), 
                  inputValue: selectedProductColor 
              },
                ]}
            />  
            <Row
                elements={[
                  {
                    type: 'selectInputNumber',
                    label: 'Mennyiség:',
                    placeholder: 'ha nincs raktáron, külső készlet',
                    inputValue: productQuantity,
                    onInputChange: (e) => onInputChange('totalPersonnel', e.target.value),
                    max: 30,
                  },
                 
                  {
                    type: 'labelText', 
                    label: 'Elérhető Max:',
                    placeholder: 'X db'},
                ]}
            />

            
            </div>
            <DynamicFormAssetExta formData={formData} onInputChange={() => {}} />
            <div className="dynamic-button-row">
            {/* Cancel button to close the form without saving */}
            <TertiaryButton label="MÉGSE" onClick={() => setFormVisible(false)} />
            {/* Save button to save the form data */}
            <PrimaryButton label="HOZZÁADÁS" onClick={() => {
              if (onSave) onSave(formData);
              setFormVisible(false);
            }} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicFormAsset;
