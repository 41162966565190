import React from 'react';
import './FormAddingButton.css';  // The updated CSS filename.
import icon_add from '../../icons/icon_add.png'

const FormAddingButton = ({ label, onClick }) => {  // onClick esemény
    return (
        <button className="form-adding-button" onClick={onClick}>
            <img src={icon_add} alt="form add icon" className="form-adding-icon" />
            <span className="form-adding-text">{label}</span>
        </button>
    );
};

export default FormAddingButton;