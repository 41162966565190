import React from 'react';
import FormAddingButton from '../Buttons/FormAddingButton';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TextDisplay from '../TextDisplay/TextDisplay';
import hu from "date-fns/locale/hu";
import icon_search from '../../icons/icon_search.png';
import { FaCalendarAlt } from 'react-icons/fa';
import './Row.css';

const Row = ({ elements, style }) => {

  // Custom Input component with a calendar icon for the DatePicker
  const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input" onClick={onClick} ref={ref}>
      {value || "Válassz dátumot"}
      <FaCalendarAlt style={{ marginLeft: '8px', color: '#4a90e2' }} />
    </button>
  ));

  return (
    <div className="row" style={style}>
      {elements.map((element, index) => {
        switch (element.type) {
          case 'select':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <select
                  className="input-select"
                  onChange={element.onInputChange}
                  value={element.inputValue || ''}
                >
                  <option value="" disabled>Kérem, válasszon!</option>
                  {element.options.map((option, idx) => (
                    <option key={idx} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            );

          case 'fieldButton':
            return (
              <div className="element" key={index}>
                <input
                  type="text"
                  className="input-field"
                  placeholder={element.placeholder}
                  onChange={element.onInputChange}
                  value={element.inputValue || ''}
                />
                <FormAddingButton
                  label={element.buttonLabel}
                  onClick={element.onButtonClick}
                />
              </div>
            );

          case 'labelField':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <input
                  type="text"
                  className="input-field"
                  placeholder={element.placeholder}
                  onChange={element.onInputChange}
                  value={element.inputValue || ''}
                />
              </div>
            );

          case 'selectInputfield':
            return (
              <div className="element" key={index}>
                <label className='select-input-label'>{element.label}</label>
                <select
                  className="input-select"
                  onChange={element.onInputChange}
                  value={element.inputValue || ''}
                >
                  <option value="" disabled>Kérem, válasszon!</option>
                  {element.options.map((option, idx) => (
                    <option key={idx} value={option.id}>{option.name}</option>
                  ))}
                </select>
                <input
                  type="number"
                  className="input-field-hour"
                  placeholder={element.placeholder}
                  onChange={element.onQuantityChange}
                  value={element.quantity || ''}
                  min="0"
                  step="1"
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            );

          case 'selectInputNumber':
            return (
              <div className="element" key={index}>
                <label className="select-input-label">{element.label}</label>
                <input
                  type="number"
                  className="input-field-number"
                  placeholder={element.placeholder}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value <= (element.max || 20)) {
                      element.onInputChange(e);
                    } else {
                      element.onInputChange({ ...e, target: { ...e.target, value: element.max || 20 } });
                    }
                  }}
                  value={element.inputValue || ''}
                  min="0"
                  max={element.max || 20}
                  step={1}
                />
              </div>
            );

          case 'labelText':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <TextDisplay text={element.text} placeholder={element.placeholder} />
              </div>
            );

          case 'textText':
            return (
              <div className="element" key={index}>
                <TextDisplay text={element.text1} placeholder={element.placeholder1} />
                <TextDisplay text={element.text2} placeholder={element.placeholder2} />
              </div>
            );

          case 'datePickerInputHour':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <div className='half-element'>
                  <DatePicker
                    selected={element.dateValue}
                    onChange={element.onDateChange}
                    placeholderText="Válassz egy dátumot"
                    customInput={<CustomDateInput />}
                    dateFormat="yyyy/MM/dd"
                    locale={hu}
                  />
                  <input
                    type="text"
                    className="input-field-hour"
                    placeholder={element.placeholder}
                    value={element.inputValue || ''}
                    onChange={element.onInputChange}
                  />
                </div>
              </div>
            );

          case 'labelDatePicker':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <DatePicker
                  selected={element.dateValue}
                  onChange={element.onDateChange}
                  customInput={<CustomDateInput />}
                  timeInputLabel="Idő:"
                  dateFormat="yyyy MMMM d h:mm"
                  showTimeInput
                  locale={hu}
                  className="custom-date-picker"
                  calendarClassName="custom-calendar"
                />
              </div>
            );

          case 'searchInputLabel':
            return (
              <div className="element" key={index}>
                <label>{element.label}</label>
                <div className="search-container">
                  <div className="search-icon">
                    <img src={icon_search} alt="Search Icon" />
                  </div>
                  <input
                    type="text"
                    placeholder={element.placeholder}
                    className="search-input"
                  />
                </div>
              </div>
            );

          case 'labelFieldXXL':
            return (
              <div className="element-xxl" key={index}>
                <label>{element.label}</label>
                <textarea
                  className="input-field-xxl"
                  rows={3}
                  placeholder={element.placeholder}
                  value={element.inputValue || ''}
                  onChange={(e) => element.onInputChange(e)}
                />
              </div>
            );

          case 'labelFieldL':
            return (
              <div className="element-l" key={index}>
                <label>{element.label}</label>
                <input
                  className="input-field-l"
                  placeholder={element.placeholder}
                  value={element.inputValue || ''}
                  onChange={(e) => element.onInputChange(e)}
                />
              </div>
            );

          case 'buttonAlone':
            return (
              <div className="element" key={index}>
                <div style={{width: '200px'}}></div>
                <FormAddingButton
                  label={element.buttonLabel}
                  onClick={element.onButtonClick}
                />
              </div>
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default Row;
