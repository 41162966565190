import React, { useState } from 'react';

import './NewProjectDataTable.css';
import AssetsSection from './Sections/AssetsSection';
import HumanSection from './Sections/HumanSection';
import LogisticSection from './Sections/LogisticSection'

const NewProjectDataTable = () => {
  const generateRandomData = (prefix, count) => {
    return Array.from({ length: count }, (_, index) => ({
      id: `${prefix}-${index + 1}`,
      name: `${prefix} elem ${index + 1}`,
      duration: `${Math.floor(Math.random() * 10) + 1} nap`,
      location: `Helyszín ${index + 1}`,
      client: `Megrendelő ${index + 1}`,
      other: `Még vm ${index + 1}`,
      any: `Bármi ${index + 1}`
    }));
  };

  const [data, setData] = useState({
    eszkozok: generateRandomData('Eszköz', 4),
    human: generateRandomData('HR', 1),
    logisztika: generateRandomData('Logisztika', 0),
    extrarendeles: generateRandomData('Extra', 0),
    kartetel: generateRandomData('Kártétel', 0)
  });

  const addNewItem = (sectionKey) => {
    // Logic to add a new item
  };

  const deleteItem = (sectionKey, id) => {
    setData((prevData) => ({
      ...prevData,
      [sectionKey]: prevData[sectionKey].filter(item => item.id !== id)
    }));
  };

  return (
    <div className="data-table-container">
      <AssetsSection 
        data={data.eszkozok} 
        onAdd={() => addNewItem('eszkoz')}
        onDelete={(id) => deleteItem('eszkoz', id)} 
      />
      <HumanSection
      data={data.human} 
      onAdd={() => addNewItem('human')}
      onDelete={(id) => deleteItem('human', id)}
      />
      <LogisticSection
      data={data.logisztika} 
      onAdd={() => addNewItem('logisztika')}
      onDelete={(id) => deleteItem('logisztika', id)}
      />
    </div>
  );
};

export default NewProjectDataTable;
