import React from 'react';
import Swal from 'sweetalert2';
import config from '../../config.js';

function Admin() {
  const handleAddUser = async () => {
    const { value: email } = await Swal.fire({
      title: 'Új Felhasználó Hozzáadása',
      input: 'email',
      inputLabel: 'Adja meg az új felhasználó e-mail címét:',
      inputPlaceholder: 'example@example.com',
      showCancelButton: true,
      confirmButtonText: 'Felhasználó Hozzáadása',
      cancelButtonText: 'Mégse',
    });

    if (email) {
      try {
        // Ellenőrzés, hogy az e-mail cím már regisztrálva van-e
        const checkResponse = await fetch(`${config.serverAddress}/api/check-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        const checkData = await checkResponse.json();

        if (checkData.is_registered) {
          // Figyelmeztetés ha az e-mail cím már regisztrálva van
          const confirm = await Swal.fire({
            title: 'Ez az e-mail cím már regisztrálva van!',
            text: 'Biztosan folytatja a regisztrációt?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Igen, folytatom',
            cancelButtonText: 'Mégse',
          });

          if (!confirm.isConfirmed) {
            // Ha a felhasználó megszakítja a folyamatot
            return;
          }
        }

        // Aktiváló e-mail küldése
        const response = await fetch(`${config.serverAddress}/api/send-activation-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });

        if (response.ok) {
          Swal.fire('Email sent!', `User with email ${email} has been added!`, 'success');
        } else {
          Swal.fire('Error!', 'Failed to send activation email.', 'error');
        }
      } catch (error) {
        Swal.fire('Error!', 'Failed to connect to the server.', 'error');
      }
    }
  };

  return (
    <div>
      <h2>Admin</h2>
      <button onClick={handleAddUser}>Felhasználó hozzáadása</button>
    </div>
  );
}

export default Admin;
