import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import './AddAccessoryModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import FeeRow from '../FeeRow/FeeRow.js';

const ModalAddAccessory = ({ isOpen, onSubmit, onClose }) => {
  // Kiegészítők állapotai
  const [accessoryCategory, setAccessoryCategory] = useState([]);
  const [accessoryGroupName, setAccessoryGroupName] = useState([]);
  const [accessoryColor, setAccessoryColor] = useState([]);
  const [accessoryQuantityUnit, setAccessoryQuantityUnit] = useState([]);
  const [accessoryPackingUnit, setAccessoryPackingUnit] = useState([]);

  const [selectedAccessoryCategory, setSelectedAccessoryCategory] = useState('');
  const [selectedAccessoryGroupName, setSelectedAccessoryGroupName] = useState('');
  const [selectedAccessoryColor, setSelectedAccessoryColor] = useState('');
  const [selectedAccessoryQuantityUnit, setSelectedAccessoryQuantityUnit] = useState('');
  const [selectedAccessoryPackingUnit, setSelectedAccessoryPackingUnit] = useState('');

  const [accessoryName, setAccessoryName] = useState('');
  const [accessoryQuantity, setAccessoryQuantity] = useState(0);
  const [accessoryWeight, setAccessoryWeight] = useState(0);
  const [accessorySize, setAccessorySize] = useState('');
  const [accessoryInventoryPrice, setAccessoryInventoryPrice] = useState(0);

  // Napi árak kiegészítők számára
  const [accessoryDay1Price, setAccessoryDay1Price] = useState(0);
  const [accessoryDay2Price, setAccessoryDay2Price] = useState(0);
  const [accessoryDay3Price, setAccessoryDay3Price] = useState(0);
  const [accessoryDay4Price, setAccessoryDay4Price] = useState(0);
  const [accessoryDay5Price, setAccessoryDay5Price] = useState(0);
  const [accessoryDay6Price, setAccessoryDay6Price] = useState(0);
  const [accessoryDay7Price, setAccessoryDay7Price] = useState(0);
  const [accessoryWeekendPrice, setAccessoryWeekendPrice] = useState(0);

  // ÚJ KIEGÉSZÍTŐ KATEGÓRIA HOZZÁADÁSA
  const [newAccessoryCategory, setNewAccessoryCategory] = useState('');

  const handleAccessoryCategorySubmit = async () => {
    // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
    const formattedAccessoryCategory = newAccessoryCategory.trim()
    ? (/[a-zA-Z]/.test(newAccessoryCategory.charAt(0))
        ? newAccessoryCategory.charAt(0).toUpperCase() + newAccessoryCategory.slice(1).toLowerCase()
        : newAccessoryCategory)
    : newAccessoryCategory;

    if (!formattedAccessoryCategory.trim()) {
        Swal.fire('Hiba', 'A kategória neve nem lehet üres!', 'error');
        return;
    }

    try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-category/add', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessory_category_name: formattedAccessoryCategory }),
        });

        if (response.ok) {
          Swal.fire('Siker!', 'Az új kategória hozzáadásra került!', 'success');
          setNewAccessoryCategory(''); // Reseteljük az input mezőt
        } else {
          const errorData = await response.json();
          if (errorData.message === 'Category already exists') {
            Swal.fire('Hiba!', 'Ez a kategória már létezik!', 'error');
          } else {
            Swal.fire('Hiba!', 'Nem sikerült hozzáadni a kategóriát!', 'error');
          }
        }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // KIEGÉSZÍTŐ KATEGÓRIÁK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchAccessoryCategories = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-category/list');
        if (response.ok) {
          const categories = await response.json();
          setAccessoryCategory(categories); // Kiegészítő kategóriák beállítása
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő kategóriákat.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchAccessoryCategories(); // Kategóriák lekérdezése a komponens betöltésekor
  }, [newAccessoryCategory]);

  // ÚJ KIEGÉSZÍTŐ CSOPORT HOZZÁADÁSA
  const [newAccessoryGroup, setNewAccessoryGroup] = useState('');

  const handleAccessoryGroupSubmit = async () => {
      const formattedAccessoryGroup = newAccessoryGroup.trim()
          ? (/[a-zA-Z]/.test(newAccessoryGroup.charAt(0))
              ? newAccessoryGroup.charAt(0).toUpperCase() + newAccessoryGroup.slice(1).toLowerCase()
              : newAccessoryGroup)
          : newAccessoryGroup;

      if (!selectedAccessoryCategory.trim()) {
          Swal.fire('Hiba', 'Kategória kiválasztása kötelező!', 'error');
          return;
      }

      if (!formattedAccessoryGroup.trim()) {
          Swal.fire('Hiba', 'A csoport neve nem lehet üres!', 'error');
          return;
      }

      try {
          const response = await fetch('http://127.0.0.1:5000/api/accessory-group/add', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  accessory_group_name: formattedAccessoryGroup,
                  accessory_selected_category_name: selectedAccessoryCategory,
              }),
          });

          if (response.ok) {
              Swal.fire('Siker!', 'Az új csoport hozzáadásra került!', 'success');
              setNewAccessoryGroup('');
              // Azonnal frissítjük a csoportok listáját
              fetchAccessoryGroups();
          } else {
              const errorData = await response.json();
              if (errorData.message === 'Group already exists') {
                  Swal.fire('Hiba!', 'Ez a csoport már létezik!', 'error');
              } else {
                  Swal.fire('Hiba!', 'Nem sikerült hozzáadni a csoportot!', 'error');
              }
          }
      } catch (error) {
          Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
  };

  // KIEGÉSZÍTŐ CSOPORTOK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  const fetchAccessoryGroups = useCallback(async () => {
      if (!selectedAccessoryCategory.trim()) {
          Swal.fire('Hiba!', 'Kérjük, válasszon egy kategóriát!', 'error');
          return;
      }
      try {
          const response = await fetch(`http://127.0.0.1:5000/api/accessory-group/list?category_name=${selectedAccessoryCategory}`);
          if (response.ok) {
              const groups = await response.json();
              setAccessoryGroupName(groups);
          } else {
              Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő csoportokat.', 'error');
          }
      } catch (error) {
          Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
  }, [selectedAccessoryCategory]);

  useEffect(() => {
      // Alaphelyzetbe állítjuk a kiválasztott csoportot és a csoportok listáját, ha a kategória változik
      setSelectedAccessoryGroupName(''); // Alapértelmezett állapot
      setAccessoryGroupName([]); // Ürítjük a csoportokat

      // Lekérdezzük a csoportokat, ha van kiválasztott kategória
      if (selectedAccessoryCategory.trim()) {
          fetchAccessoryGroups();
      }
  }, [selectedAccessoryCategory, fetchAccessoryGroups]);

  // ÚJ KIEGÉSZÍTŐ SZÍN HOZZÁADÁSA
  const [newAccessoryColor, setNewAccessoryColor] = useState('');

  const handleAccessoryColorSubmit = async () => {
    // Ha az első karakter betű, akkor az első betűt nagyra, a többit kicsire alakítjuk
    const formattedAccessoryColor = newAccessoryColor.trim()
    ? (/[a-zA-Z]/.test(newAccessoryColor.charAt(0))
        ? newAccessoryColor.charAt(0).toUpperCase() + newAccessoryColor.slice(1).toLowerCase()
        : newAccessoryColor)
    : newAccessoryColor;

    if (!formattedAccessoryColor.trim()) {
      Swal.fire('Hiba', 'A szín neve nem lehet üres!', 'error');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:5000/api/accessory-color/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accessory_color_name: formattedAccessoryColor }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új szín hozzáadásra került!', 'success');
        setNewAccessoryColor('');
      } else {
        const errorData = await response.json();
        if (errorData.message === 'Color already exists') {
          Swal.fire('Hiba!', 'Ez a szín már létezik!', 'error');
        } else {
          Swal.fire('Hiba!', 'Nem sikerült hozzáadni a színt!', 'error');
        }
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // KIEGÉSZÍTŐ SZÍNEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchAccessoryColors = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-color/list');
      if (response.ok) {
        const colors = await response.json();
        setAccessoryColor(colors);
      } else {
        Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő színeket.', 'error');
      }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchAccessoryColors();
  }, [newAccessoryColor]);

  // ÚJ KIEGÉSZÍTŐ MENNYISÉGI EGYSÉG HOZZÁADÁSA
  const [newAccessoryQuantityUnit, setNewAccessoryQuantityUnit] = useState('');

  const handleAccessoryQuantityUnitSubmit = async () => {
    // A név formázása: minden betű nagybetűsre váltása
    const formattedAccessoryQuantityUnit = newAccessoryQuantityUnit.trim().toUpperCase();

    if (!formattedAccessoryQuantityUnit.trim()) {
      Swal.fire('Hiba', 'A mennyiségi egység neve nem lehet üres!', 'error');
      return;
    }

    try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-quantity-unit/add', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessory_quantity_unit_name: formattedAccessoryQuantityUnit }),
        });

        if (response.ok) {
          Swal.fire('Siker!', 'Az új mennyiségi egység hozzáadásra került!', 'success');
          setNewAccessoryQuantityUnit('');
        } else {
          const errorData = await response.json();
          if (errorData.message === 'Quantity unit already exists') {
            Swal.fire('Hiba!', 'Ez a mennyiségi egység már létezik!', 'error');
          } else {
            Swal.fire('Hiba!', 'Nem sikerült hozzáadni a mennyiségi egységet!', 'error');
          }
        }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // KIEGÉSZÍTŐ MENNYISÉGI EGYSÉGEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchAccessoryQuantityUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-quantity-unit/list');
        if (response.ok) {
          const quantityUnits = await response.json();
          setAccessoryQuantityUnit(quantityUnits); // Kiegészítő mennyiségi egységek beállítása
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni a kiegészítő mennyiségi egységeket.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchAccessoryQuantityUnits(); // Mennyiségi egységek lekérdezése a komponens betöltésekor
  }, [newAccessoryQuantityUnit]);

  // ÚJ KIEGÉSZÍTŐ CSOMAGOLÁSI EGYSÉG HOZZÁADÁSA
  const [newAccessoryPackingUnit, setNewAccessoryPackingUnit] = useState('');

  const handleAccessoryPackingUnitSubmit = async () => {
    if (!newAccessoryPackingUnit.trim()) {
      Swal.fire('Hiba', 'A csomagolási egység neve nem lehet üres!', 'error');
      return;
    }

    try {
      const response = await fetch('http://127.0.0.1:5000/api/accessory-packing-unit/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accessory_packing_unit_name: newAccessoryPackingUnit }),
      });

      if (response.ok) {
        Swal.fire('Siker!', 'Az új csomagolási egység hozzáadásra került!', 'success');
        setNewAccessoryPackingUnit('');
      } else {
        const errorData = await response.json();
        Swal.fire('Hiba!', errorData.message, 'error');
      }
    } catch (error) {
      Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
    }
  };

  // KIEGÉSZÍTŐ CSOMAGOLÁSI EGYSÉGEK LEKÉRDEZÉSE AZ ADATBÁZISBÓL
  useEffect(() => {
    const fetchAccessoryPackingUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/accessory-packing-unit/list');
        if (response.ok) {
          const packingUnits = await response.json();
          setAccessoryPackingUnit(packingUnits); // Kiegészítő csomagolási egységek beállítása
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni a csomagolási egységeket.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    fetchAccessoryPackingUnits(); // Csomagolási egységek lekérdezése a komponens betöltésekor
  }, [newAccessoryPackingUnit]);

  const handleSubmit = () => {
    // Validáció
    if (!accessoryName.trim()) {
        Swal.fire('Hiba', 'A kiegészítő neve nem lehet üres!', 'error');
        return;
    }
    if (!selectedAccessoryCategory || selectedAccessoryCategory === "" || selectedAccessoryCategory === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy kategóriát!', 'error');
        return;
    }
    if (!selectedAccessoryGroupName || selectedAccessoryGroupName === "" || selectedAccessoryGroupName === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy csoportot!', 'error');
        return;
    }
    if (!selectedAccessoryColor || selectedAccessoryColor === "" || selectedAccessoryColor === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy színt vagy anyagot!', 'error');
        return;
    }
    if (!selectedAccessoryQuantityUnit || selectedAccessoryQuantityUnit === "" || selectedAccessoryQuantityUnit === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy mennyiségi egységet!', 'error');
        return;
    }
    if (!selectedAccessoryPackingUnit || selectedAccessoryPackingUnit === "" || selectedAccessoryPackingUnit === "Kérem, válasszon!") {
        Swal.fire('Hiba', 'Válassz egy csomagolási egységet!', 'error');
        return;
    }
    if (isNaN(accessoryQuantity) || accessoryQuantity <= 0) {
        Swal.fire('Hiba', 'A mennyiségnek pozitív számnak kell lennie!', 'error');
        return;
    }
    if (isNaN(accessoryWeight) || accessoryWeight <= 0) {
        Swal.fire('Hiba', 'A súlynak pozitív számnak kell lennie!', 'error');
        return;
    }
    if (!accessorySize.trim()) {
        Swal.fire('Hiba', 'A méret nem lehet üres!', 'error');
        return;
    }
    if (isNaN(accessoryInventoryPrice) || accessoryInventoryPrice <= 0) {
      Swal.fire('Hiba', 'A leltári értéknek pozitív számnak kell lennie!', 'error');
      return;
    }

    // Árak validációja
    const prices = [
        Number(accessoryDay1Price),
        Number(accessoryDay2Price),
        Number(accessoryDay3Price),
        Number(accessoryDay4Price),
        Number(accessoryDay5Price),
        Number(accessoryDay6Price),
        Number(accessoryDay7Price),
        Number(accessoryWeekendPrice)
    ];

    if (prices.some((price, index) => {
        console.log(`Checking price ${index + 1}:`, price, typeof price);
        if (isNaN(price)) {
            Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben csak számokat adhat meg!`, 'error');
            return true;
        }
        if (price <= 0) {
            Swal.fire('Hiba', `A(z) ${index + 1}. napi ár mezőben pozitív számot kell megadni!`, 'error');
            return true;
        }
        return false;
    })) {
        return;
    }

    // Minden adat helyes, továbbítjuk
    const accessoryData = {
      accessory_name: accessoryName,
      accessory_category: selectedAccessoryCategory,
      accessory_group_name: selectedAccessoryGroupName,
      accessory_color: selectedAccessoryColor,
      accessory_weight: Number(accessoryWeight),
      accessory_size: accessorySize,
      accessory_quantity: Number(accessoryQuantity),
      accessory_quantity_unit: selectedAccessoryQuantityUnit,
      accessory_packing_unit: selectedAccessoryPackingUnit,
      accessory_inventory_value: accessoryInventoryPrice,
      accessory_prices: {
        day1: prices[0],
        day2: prices[1],
        day3: prices[2],
        day4: prices[3],
        day5: prices[4],
        day6: prices[5],
        day7: prices[6],
        weekend: prices[7]
      }
    };

    // A accessoryData objektum elküldése
    console.log('Accessory data being submitted:', accessoryData);
    onSubmit(accessoryData);
  };

  if (!isOpen) {
    console.log('Modal is not open, returning null.');
    return null; // Ellenőrizzük, hogy meg kell-e jeleníteni a modal-t
  }

  return (
    <div className="modal-b-overlay">
      <div className="modal-b-content">
        <h2>ÚJ KIEGÉSZÍTŐ HOZZÁADÁSA</h2>  
        {/* Add New Category + Button, Add New Group + Button */}
        <Row
          elements={[
            { 
              type: 'fieldButton', 
              placeholder: 'Új Kategória', 
              buttonLabel: 'ÚJ KATEGÓRIA', 
              onButtonClick: handleAccessoryCategorySubmit,
              onInputChange: (e) => setNewAccessoryCategory(e.target.value),
              inputValue: newAccessoryCategory 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Új Csoport', 
              buttonLabel: 'ÚJ CSOPORT', 
              onButtonClick: handleAccessoryGroupSubmit,
              onInputChange: (e) => setNewAccessoryGroup(e.target.value),
              inputValue: newAccessoryGroup
            }
          ]}
        />  
        {/* Choose Category And Group */}
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Kategória:', 
              options: accessoryCategory, 
              onInputChange: (e) => setSelectedAccessoryCategory(e.target.value), 
              inputValue: selectedAccessoryCategory 
            },
            { 
              type: 'select', 
              label: 'Csoport:', 
              options: accessoryGroupName, 
              onInputChange: (e) => setSelectedAccessoryGroupName(e.target.value), 
              inputValue: selectedAccessoryGroupName 
            }
          ]}
        />
        {/* Row 3: Product Name */}
        <Row
            elements={[
            { 
                type: 'labelField', 
                label: 'Kiegészítő Neve:', 
                placeholder: 'Termék Neve', 
                onInputChange: (e) => setAccessoryName(e.target.value),
                inputValue: accessoryName 
            }
            ]}
        />   
        {/* Color, Material Designation */}
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'SZÍN | ANYAG | STB:', 
              options: accessoryColor, 
              onInputChange: (e) => setSelectedAccessoryColor(e.target.value), 
              inputValue: selectedAccessoryColor 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Szín|Anyag Megnevezése', 
              buttonLabel: 'ÚJ SZÍN | ANYAG', 
              onButtonClick: handleAccessoryColorSubmit,
              onInputChange: (e) => setNewAccessoryColor(e.target.value),
              inputValue: newAccessoryColor 
            }
          ]}
        />  
        {/* Select + Label, Field + Button */}
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Mennyiség egysége:', 
              options: accessoryQuantityUnit, 
              onInputChange: (e) => setSelectedAccessoryQuantityUnit(e.target.value), 
              inputValue: selectedAccessoryQuantityUnit 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Mennyiség egység', 
              buttonLabel: 'ÚJ egység', 
              onButtonClick: handleAccessoryQuantityUnitSubmit ,
              onInputChange: (e) => setNewAccessoryQuantityUnit(e.target.value),
              inputValue: newAccessoryQuantityUnit 
            }
          ]}
        />  
        {/* Quantity + Weight */}
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Mennyiség:', 
              placeholder: 'Mennyiség', 
              onInputChange: (e) => setAccessoryQuantity(e.target.value),
              inputValue: accessoryQuantity 
            },
            { 
              type: 'labelField', 
              label: 'SÚLY (kg):', 
              placeholder: 'pl. 15', 
              onInputChange: (e) => setAccessoryWeight(e.target.value),
              inputValue: accessoryWeight 
            }
          ]}
        />  
        {/* Packing Unit */}
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Csomagolás egysége:', 
              options: accessoryPackingUnit, 
              onInputChange: (e) => setSelectedAccessoryPackingUnit(e.target.value), 
              inputValue: selectedAccessoryPackingUnit 
            },
            { 
              type: 'fieldButton', 
              placeholder: 'Egység megnevezése', 
              buttonLabel: 'Új Csom. Egység', 
              onButtonClick: handleAccessoryPackingUnitSubmit,
              onInputChange: (e) => setNewAccessoryPackingUnit(e.target.value),
              inputValue: newAccessoryPackingUnit 
            }
          ]}
        />  
        {/* Size */}
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Méret:', 
              placeholder: 'Hossz x Szélesség', 
              onInputChange: (e) => setAccessorySize(e.target.value),
              inputValue: accessorySize 
            },
            { 
              type: 'labelField', 
              label: 'Leltári érték:', 
              placeholder: '65 000 Ft', 
              onInputChange: (e) => setAccessoryInventoryPrice(e.target.value),
              inputValue: accessoryInventoryPrice
            }
          ]}
        />
        {/* Ár Hozzáadása */}
        <h3>ÁR HOZZÁADÁSA</h3>
        <FeeRow
          elements={[
            { type: 'labelField', label: '1 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay1Price(e.target.value), inputValue: accessoryDay1Price },
            { type: 'labelField', label: '2 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay2Price(e.target.value), inputValue: accessoryDay2Price },
            { type: 'labelField', label: '3 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay3Price(e.target.value), inputValue: accessoryDay3Price },
            { type: 'labelField', label: '4 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay4Price(e.target.value), inputValue: accessoryDay4Price }
          ]}
        />  
        <FeeRow
          elements={[
            { type: 'labelField', label: '5 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay5Price(e.target.value), inputValue: accessoryDay5Price },
            { type: 'labelField', label: '6 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay6Price(e.target.value), inputValue: accessoryDay6Price },
            { type: 'labelField', label: '7 NAP (FT):', placeholder: '10 00', onInputChange: (e) => setAccessoryDay7Price(e.target.value), inputValue: accessoryDay7Price },
            { type: 'labelField', label: 'P-SZ-V:', placeholder: '10 00', onInputChange: (e) => setAccessoryWeekendPrice(e.target.value), inputValue: accessoryWeekendPrice }
          ]}
        />
        {/* Gombok */}
        <div className="button-row">
          <TertiaryButton label="MÉGSE" onClick={onClose} />
          <PrimaryButton label="LÉTREHOZÁS" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );  
};

export default ModalAddAccessory;
