import React from 'react';
import './PrimaryButton.css'; // Import the CSS for styling

const PrimaryButton = ({ label,onClick }) => {
    return (
        <button className="primary-button" onClick={onClick}>
            <span className="primary-button-text">{label}</span>
        </button>
    );
};

export default PrimaryButton;
