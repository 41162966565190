import React, { useState } from 'react';
import Row from '../Row/Row';
import './DynamicFormLogistic.css';
import AddingButton from '../Buttons/AddingButton';
import TertiaryButton from '../Buttons/TertiaryButton';

const DynamicFormLogisticExtra = ({ formData, onInputChange, onSave, onCancel }) => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [arrivalDate, setArrivalDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const toggleFormVisibility = () => setFormVisible(!isFormVisible);

  return (
    <div className="dynamic-form">
      <div className="button-container">
        {!isFormVisible && (
          <AddingButton label="EXTRA CÍM" onClick={toggleFormVisibility} />
        )}
      </div>
      {isFormVisible && (
        <div className="d-form-container visible">
          <div className="rows-container">
            
            <h4 className='title'>EXTRA CÍM</h4>
             <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Találkozó cím:',
                  placeholder: 'Cím megadása',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            {/* Row for input fields */}
            <Row
              elements={[
                {
                  type: 'labelDatePicker',
                  label: 'Kezdés dátum | időpont:',
                  dateValue: startDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
                {
                    type: 'select',
                    label: 'Behajtási engedély:',
                    options: ['Igen', 'Nem'],
                    inputValue: formData.selectField,
                    onInputChange: (e) => onInputChange('selectField', e.target.value),
                    name: 'selectField',
                },
              ]}
            />
            <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Találkozó cím:',
                  placeholder: 'Cím megadása',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            <Row
              elements={[
                {
                  type: 'labelDatePicker',
                  label: 'Érkezés dátum | időpont:',
                  dateValue: arrivalDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
                {
                  type: 'labelDatePicker',
                  label: 'Végzés dátuma | időpont:',
                  dateValue: endDate,
                  onDateChange: (date) => onInputChange('startDate', date),
                },
              ]}
            />
             <Row
              elements={[
                {
                  type: 'labelFieldL',
                  label: 'Egyéb:',
                  placeholder: 'mit vesz fel, kontakt stb.',
                  inputValue: formData.meetingAddress,
                  onInputChange: (e) => onInputChange('meetingAddress', e.target.value),
                  name: 'meetingAddress',
                },
              ]}
            />
            {/* Add more Row components here as needed */}
          </div>
          
          <div className="dynamic-button-row-extra">
            <TertiaryButton label="MÉGSE" onClick={() => {
              setFormVisible(false);
              if (onCancel) onCancel();
            }} />
            
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicFormLogisticExtra;
