// FeeRow.js
import React from 'react';
import './FeeRow.css';

const FeeRow = ({ elements, style }) => {
  return (
    <div className="fee-row" style={style}>
      {elements.map((element, index) => {
        switch (element.type) {
          case 'labelField':
            return (
              <div className="fee-element" key={index}>
                <label className="fee-label">{element.label}</label>
                <input 
                  type="text" 
                  className="fee-input-field" 
                  placeholder={element.placeholder} 
                  value={element.inputValue} // Állapotérték beállítása
                  onChange={element.onInputChange} // Eseménykezelő hozzáadása
                  pattern="\d*" // Csak számok engedélyezése
                  onInput={(e) => {
                    // Csak számok engedélyezése és felesleges karakterek eltávolítása
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default FeeRow;
