import React, { useState } from 'react';
import Row from '../Row/Row';  // Assuming you have the Row component ready
import './FormContainer.css'  // We'll style the container separately

const FormContainer = () => {
  const [selectedDate, setSelectedDate] = useState(null); // Initialize date state

  return (
    <div className="form-container">
      <div className='header-title'><h1>ÚJ PROJEKT Létrehozása</h1></div>
      <div className='titles'>
        <h3>Projekt Típus kiválasztás</h3>
      </div>
      <div className='form-rows'>
        <Row
          elements={[
            {
              label: 'Projekt típus:',
              type: 'select',
              options: ['Full Play','Dry Hire', 'Raktári munka']
            },
            {
              type: 'labelField',
              label: 'Projekt elnevezése:',
              placeholder: 'pl. CsipkeJózsika Vonul',
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            { label: 'Dátum:', type: 'labelDatePicker' },
            { label: 'webcím:', type: 'labelField', placeholder: 'https.//szigetEllés.hu'}
          ]}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          style={{marginBottom: '10px'}}
        />
        <Row
          elements={[
            {type: 'labelText', label: 'Projekt Kód:', placeholder: 'név'},
            {
              type: 'labelField',
              label: 'Megrendelői projektkód:',
              placeholder: 'Xtra számlázási igény',
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
      </div>
      <div className='titles'>
        <h3>Projekt stáb</h3>
      </div>
      <div className='form-rows'>
        <Row
          elements={[
            {
              label: 'Projekt Kapcsolattartó:',
              type: 'select',
              options: ['Balogh Zsolt', 'Option 2']
            },
            {
              type: 'textText',
              text1: 'First Text',
              placeholder1: 'First Placeholder',
              text2: 'Second Text',
              placeholder2: 'Second Placeholder'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {
              label: 'Projekt Manager:',
              type: 'select',
              options: ['Bogeropolisz', 'Option 2']
            },
            {
              type: 'textText',
              text1: 'First Text',
              placeholder1: 'First Placeholder',
              text2: 'Second Text',
              placeholder2: 'Second Placeholder'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {
              label: 'Építésvezető:',
              type: 'select',
              options: ['Márcselló', 'Option 2']
            },
            {
              type: 'textText',
              text1: 'First Text',
              placeholder1: 'First Placeholder',
              text2: 'Second Text',
              placeholder2: 'Second Placeholder'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
      </div>
      <div className='titles'>
        <h3>Megrendelő</h3>
      </div>
      <div className='form-rows'>
        <Row
          elements={[
            { label: 'Név | Cégnév:', type: 'searchInputLabel', placeholder: 'Cég Keresése'},
            { type: 'buttonAlone', buttonLabel: 'ÚJ CÉG Hozzáadása'}
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {type: 'labelText', label: 'Képviseli:', placeholder: 'név'},
            {
              type: 'textText',
              placeholder1: 'tel',
              placeholder2: 'mail'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {type: 'labelText', label: 'Cégadatok:', placeholder: 'cím'},
            {
              type: 'textText',
              placeholder1: 'adószám',
              placeholder2: 'egyéb info'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            { label: 'Helyszíni kapcsolattartó:', type: 'searchInputLabel', placeholder:'Kapcsolattartó Keresése'},
            { type: 'buttonAlone', buttonLabel: 'ÚJ Kapcsolattartó'}
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {type: 'labelText', label: 'Kapcsolattartó:', placeholder: 'név'},
            {
              type: 'textText',
              placeholder1: 'tel',
              placeholder2: 'mail'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
      </div>
      <div className='titles'>
        <h3>Rendezvény</h3>
      </div>
      <div className='form-rows'>
        <Row
          elements={[
            { label: 'Helyszín:', type: 'searchInputLabel', placeholder:'Helyszín Keresése'},
            { type: 'buttonAlone', buttonLabel: 'ÚJ Helyszín'}
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {type: 'labelText', label: 'Helyszín Adatai:', placeholder: 'Irányító szám | Város'},
            {
              type: 'textText',
              placeholder1: 'Cím',
              placeholder2: 'Megjegyzés'
            }
          ]}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            { label: 'Építés kezdete:', type: 'labelDatePicker'},
            { label: 'Építés vége:', type: 'labelDatePicker'},
          ]}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            { label: 'Botás kezdete:', type: 'labelDatePicker'},
            { label: 'Bontás vége:', type: 'labelDatePicker'},
          ]}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          style={{ marginBottom: '10px' }}
        />
        <Row
          elements={[
            {label: 'Munka részletezése:', type: 'labelFieldXXL', placeholder:'Rendezvény egyéb információinak rövid leírása'}
          ]}
        />
      </div>
    </div>
  );
};

export default FormContainer;
