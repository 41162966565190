import React from 'react';
import './TertiaryButton.css';  // Import the CSS for styling

const TertiaryButton = ({ label, onClick }) => {
    return (
        <button className="tertiary-button" onClick={onClick}>
            <span className="tertiary-button-text">{label}</span>
        </button>
    );
};

export default TertiaryButton;
