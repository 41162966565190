import React, { useState } from 'react';
import './SourceSwitch.css';
import PartnersTable from './PartnersTable';
import ContactsTable from './ContactsTable';

const SourceSwitch = () => {
  const [isComponentA, setIsComponentA] = useState(true);

  const handleToggle = () => {
    setIsComponentA(!isComponentA);
  };

  return (
    <div>
      {/* Toggle Switch */}
      <div className='container'>
      <div className="onoffswitch">
        <input
          type="checkbox"
          id="myonoffswitch"
          className="onoffswitch-checkbox"
          checked={!isComponentA}
          onChange={handleToggle}
        />
        <label htmlFor="myonoffswitch" className="onoffswitch-label">
          <div className="onoffswitch-inner"></div>
          <div className="onoffswitch-switch"></div>
        </label>
      </div>

      <div className="component-container">
        {isComponentA ? <PartnersTable /> : <ContactsTable />}
      </div>
      
    </div>
    </div>
  );
};

export default SourceSwitch;
