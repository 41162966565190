import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
import './EditPartModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';

const EditPartModal = ({ isOpen, partId, onUpdate, onClose }) => {
  const [partCategory, setPartCategory] = useState([]);
  const [partFilteredGroupName, setPartFilteredGroupName] = useState([]);
  const [partColor, setPartColor] = useState([]);
  const [partQuantityUnit, setPartQuantityUnit] = useState([]);

  const [selectedPartCategory, setSelectedPartCategory] = useState('');
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectedPartColor, setSelectedPartColor] = useState('');
  const [selectedPartQuantityUnit, setSelectedPartQuantityUnit] = useState('');

  const [partName, setPartName] = useState('');
  const [partQuantity, setPartQuantity] = useState(0);
  const [partWeight, setPartWeight] = useState(0);
  const [partSize, setPartSize] = useState('');
  const [partWashingPrice, setPartWashingPrice] = useState(0);
  const [partInventoryPrice, setPartInventoryPrice] = useState(0);

  // Defining fetchGroups as a callback so it can be used in useEffect dependencies
  const fetchGroups = useCallback(async (categoryName) => {
    try {
      const response = await fetch(`http://127.0.0.1:5000/api/part-group/list?category_name=${categoryName}`);
      if (response.ok) {
        const groups = await response.json();
        setPartFilteredGroupName(groups);
      }
    } catch (error) {
      console.error("Hiba a csoportok lekérdezésekor", error);
    }
  }, []);

  useEffect(() => {
    const fetchPartDetails = async () => {
      try {
        const response = await fetch(`http://127.0.0.1:5000/api/parts/${partId}`);
        if (response.ok) {
          const partData = await response.json();
          setPartName(partData.name);
          setSelectedPartCategory(partData.category);
          setSelectedGroupName(partData.group_name);
          setSelectedPartColor(partData.color);
          setSelectedPartQuantityUnit(partData.quantity_unit);
          setPartQuantity(partData.quantity);
          setPartWeight(partData.weight);
          setPartSize(partData.size);
          setPartWashingPrice(partData.wash_price);
          setPartInventoryPrice(partData.inventory_value);
        } else {
          Swal.fire('Hiba!', 'Nem sikerült lekérdezni az alkatrész adatait.', 'error');
        }
      } catch (error) {
        Swal.fire('Hiba!', 'Nem sikerült kapcsolatba lépni a szerverrel.', 'error');
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-category/list');
        if (response.ok) {
          const categories = await response.json();
          setPartCategory(categories);
        }
      } catch (error) {
        console.error("Hiba a kategóriák lekérdezésekor", error);
      }
    };

    const fetchColors = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-color/list');
        if (response.ok) {
          const colors = await response.json();
          setPartColor(colors);
        }
      } catch (error) {
        console.error("Hiba a színek lekérdezésekor", error);
      }
    };

    const fetchQuantityUnits = async () => {
      try {
        const response = await fetch('http://127.0.0.1:5000/api/part-quantity-unit/list');
        if (response.ok) {
          const units = await response.json();
          setPartQuantityUnit(units);
        }
      } catch (error) {
        console.error("Hiba a mennyiségi egységek lekérdezésekor", error);
      }
    };

    if (partId && isOpen) {
      fetchPartDetails();
      fetchCategories();
      fetchColors();
      fetchQuantityUnits();
    }
  }, [partId, isOpen]);

  useEffect(() => {
    if (selectedPartCategory) {
      fetchGroups(selectedPartCategory);
    }
  }, [selectedPartCategory, fetchGroups]);

  const handleSubmit = () => {
    // Validáció
    if (!partName.trim()) {
      Swal.fire('Hiba', 'Az alkatrész neve nem lehet üres!', 'error');
      return;
    }
    if (!selectedPartCategory || selectedPartCategory === "" || selectedPartCategory === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy kategóriát!', 'error');
      return;
    }
    if (!selectedGroupName || selectedGroupName === "" || selectedGroupName === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy csoportot!', 'error');
      return;
    }
    if (!selectedPartColor || selectedPartColor === "" || selectedPartColor === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy színt vagy anyagot!', 'error');
      return;
    }
    if (!selectedPartQuantityUnit || selectedPartQuantityUnit === "" || selectedPartQuantityUnit === "Kérem, válasszon!") {
      Swal.fire('Hiba', 'Válassz egy mennyiségi egységet!', 'error');
      return;
    }
    if (isNaN(partQuantity) || partQuantity <= 0) {
      Swal.fire('Hiba', 'A mennyiségnek pozitív számnak kell lennie!', 'error');
      return;
    }
    if (isNaN(partWeight) || partWeight <= 0) {
      Swal.fire('Hiba', 'A súlynak pozitív számnak kell lennie!', 'error');
      return;
    }
    if (!partSize.trim()) {
      Swal.fire('Hiba', 'A méret nem lehet üres!', 'error');
      return;
    }
    if (isNaN(partWashingPrice) || partWashingPrice < 0) {
      Swal.fire('Hiba', 'A mosatási díj nem lehet negatív szám!', 'error');
      return;
    }
    if (isNaN(partInventoryPrice) || partInventoryPrice <= 0) {
      Swal.fire('Hiba', 'A leltári értéknek pozitív számnak kell lennie!', 'error');
      return;
    }

    // Az összes adat helyes, folytatjuk az elküldést
    const productData = {
      part_name: partName,                          // Alkatrész neve
      part_category: selectedPartCategory,          // Alkatrész kategória
      part_group_name: selectedGroupName,           // Alkatrész csoport
      part_color: selectedPartColor,                // Alkatrész színe
      part_weight: partWeight,                      // Alkatrész súlya
      part_size: partSize,                          // Alkatrész mérete
      part_quantity: partQuantity,                  // Alkatrész mennyisége
      part_quantity_unit: selectedPartQuantityUnit, // Alkatrész mennyiségi egysége
      part_inventory_value: partInventoryPrice,     // Leltári érték
      part_wash_price: partWashingPrice             // Mosási díj (csomagolási egység)
    };

    // A productData objektum elküldése
    console.log('Product data being submitted:', productData);
    onUpdate(productData);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-b-overlay">
      <div className="modal-b-content">
        <h2>Alkatrész Szerkesztése</h2>
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Kategória:', 
              options: partCategory, 
              onInputChange: (e) => setSelectedPartCategory(e.target.value), 
              inputValue: selectedPartCategory 
            },
            { 
              type: 'select', 
              label: 'Csoport:', 
              options: partFilteredGroupName, 
              onInputChange: (e) => setSelectedGroupName(e.target.value), 
              inputValue: selectedGroupName 
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Szín:', 
              options: partColor, 
              onInputChange: (e) => setSelectedPartColor(e.target.value), 
              inputValue: selectedPartColor 
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Alkatrész Neve:', 
              placeholder: 'Alkatrész Neve', 
              onInputChange: (e) => setPartName(e.target.value),
              inputValue: partName
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'select', 
              label: 'Mennyiség egysége:', 
              options: partQuantityUnit, 
              onInputChange: (e) => setSelectedPartQuantityUnit(e.target.value), 
              inputValue: selectedPartQuantityUnit 
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Mennyiség:', 
              placeholder: 'Mennyiség', 
              onInputChange: (e) => setPartQuantity(e.target.value),
              inputValue: partQuantity
            },
            { 
              type: 'labelField', 
              label: 'Súly (kg):', 
              placeholder: 'Súly', 
              onInputChange: (e) => setPartWeight(e.target.value),
              inputValue: partWeight
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Méret:', 
              placeholder: 'Méret', 
              onInputChange: (e) => setPartSize(e.target.value),
              inputValue: partSize
            }
          ]}
        />
        <Row
          elements={[
            { 
              type: 'labelField', 
              label: 'Mosatási Díj:', 
              placeholder: 'Mosatási Díj', 
              onInputChange: (e) => setPartWashingPrice(e.target.value),
              inputValue: partWashingPrice
            },
            { 
              type: 'labelField', 
              label: 'Leltári Érték:', 
              placeholder: 'Leltári Érték', 
              onInputChange: (e) => setPartInventoryPrice(e.target.value),
              inputValue: partInventoryPrice
            }
          ]}
        />
        <div className="button-row">
          <TertiaryButton label="Mégse" onClick={onClose} />
          <PrimaryButton label="Mentés" onClick={handleSubmit} />
        </div>        
      </div>
    </div>
  );
};

export default EditPartModal;
