import React, { useState } from 'react';
import '../InventoryDataTable/InventoryDataTable.css';
import Pagination from '../Pagination/Pagination';

const ContactsTable = ({ data = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentData = data.slice(firstItemIndex, lastItemIndex);

  return (
    <div className="data-table-container">
      <div className="table-scroll-container">
        <table className="data-table">
          <colgroup>
            <col style={{ width: '300px' }} />
            <col style={{ width: '300px' }} />
            <col style={{ width: '300px' }} />
            <col style={{ width: '300px' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Név</th>
              <th>Telefonszám</th>
              <th>Email</th>
              <th>Egyéb info</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              currentData.map((contact) => (
                <tr key={contact.id}>
                  <td>{contact.contactName}</td>
                  <td>{contact.mobileNumber}</td>
                  <td>{contact.email}</td>
                  <td>{contact.additionalInfo}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" style={{ textAlign: 'center', height: '50px' }}>
                  Nincsenek partnerek ebben a kategóriában.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <Pagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default ContactsTable;
