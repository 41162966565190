import React from 'react';

function Quotations() {
  return (
    <div>
      <h2>Projektek</h2>
      <p>Form for creating a projects will go here...</p>
    </div>
  );
}

export default Quotations;