import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2';
/*import './EditProductModal.css';*/
import '../AddProductModal/AddProductModal.css';
import Row from '../Row/Row.js';
import TertiaryButton from '../Buttons/TertiaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import FeeRow from '../FeeRow/FeeRow.js';

const EditProductModal = ({ isOpen, productId, onUpdate, onClose }) => {
    const [productCategory, setProductCategory] = useState([]);
    const [productGroupName, setProductGroupName] = useState([]);
    const [productColor, setProductColor] = useState([]);
    const [productQuantityUnit, setProductQuantityUnit] = useState([]);
    const [productPackingUnit, setProductPackingUnit] = useState([]);

    const [selectedProductCategory, setSelectedProductCategory] = useState('');
    const [selectedProductGroupName, setSelectedProductGroupName] = useState('');
    const [selectedProductColor, setSelectedProductColor] = useState('');
    const [selectedProductQuantityUnit, setSelectedProductQuantityUnit] = useState('');
    const [selectedProductPackingUnit, setSelectedProductPackingUnit] = useState('');

    const [productName, setProductName] = useState('');
    const [productQuantity, setProductQuantity] = useState(0);
    const [productSize, setProductSize] = useState('');

    const [productDay1Price, setProductDay1Price] = useState(0);
    const [productDay2Price, setProductDay2Price] = useState(0);
    const [productDay3Price, setProductDay3Price] = useState(0);
    const [productDay4Price, setProductDay4Price] = useState(0);
    const [productDay5Price, setProductDay5Price] = useState(0);
    const [productDay6Price, setProductDay6Price] = useState(0);
    const [productDay7Price, setProductDay7Price] = useState(0);
    const [productWeekendPrice, setProductWeekendPrice] = useState(0);

    // Alkatrészek állapotai
    const [selectedPart1, setSelectedPart1] = useState('');
    const [selectedPart2, setSelectedPart2] = useState('');
    const [selectedPart3, setSelectedPart3] = useState('');
    const [selectedPart4, setSelectedPart4] = useState('');
    const [selectedPart5, setSelectedPart5] = useState('');
    const [selectedPart6, setSelectedPart6] = useState('');

    const [selectedPartQuantity1, setSelectedPartQuantity1] = useState(0);
    const [selectedPartQuantity2, setSelectedPartQuantity2] = useState(0);
    const [selectedPartQuantity3, setSelectedPartQuantity3] = useState(0);
    const [selectedPartQuantity4, setSelectedPartQuantity4] = useState(0);
    const [selectedPartQuantity5, setSelectedPartQuantity5] = useState(0);
    const [selectedPartQuantity6, setSelectedPartQuantity6] = useState(0);

    // A kiválasztott alkatrészek tárolása
    const [productParts, setProductParts] = useState(Array(6).fill({ part_id: '', part_name: '', quantity: 0 }));

    // Kiegészítők állapotai
    const [selectedAccessoryProduct1, setSelectedAccessoryProduct1] = useState('');
    const [selectedAccessoryProduct2, setSelectedAccessoryProduct2] = useState('');
    const [selectedAccessoryProduct3, setSelectedAccessoryProduct3] = useState('');
    const [selectedAccessoryProduct4, setSelectedAccessoryProduct4] = useState('');
    const [selectedAccessoryProduct5, setSelectedAccessoryProduct5] = useState('');
    const [selectedAccessoryProduct6, setSelectedAccessoryProduct6] = useState('');

    const [selectedAccessoryQuantity1, setSelectedAccessoryQuantity1] = useState(0);
    const [selectedAccessoryQuantity2, setSelectedAccessoryQuantity2] = useState(0);
    const [selectedAccessoryQuantity3, setSelectedAccessoryQuantity3] = useState(0);
    const [selectedAccessoryQuantity4, setSelectedAccessoryQuantity4] = useState(0);
    const [selectedAccessoryQuantity5, setSelectedAccessoryQuantity5] = useState(0);
    const [selectedAccessoryQuantity6, setSelectedAccessoryQuantity6] = useState(0);

    // Kiválasztott kiegészítők tárolása
    const [accessoryProducts, setAccessoryProducts] = useState(Array(6).fill({ accessory_id: '', accessory_name: '', quantity: 0 }));

    // Alkatrészek darabszámának frissítése kiválasztás szerint
    useEffect(() => {
        setSelectedPartQuantity1(selectedPart1 ? 1 : 0);
        }, [selectedPart1]);
    
        useEffect(() => {
        setSelectedPartQuantity2(selectedPart2 ? 1 : 0);
        }, [selectedPart2]);
    
        useEffect(() => {
        setSelectedPartQuantity3(selectedPart3 ? 1 : 0);
        }, [selectedPart3]);
    
        useEffect(() => {
        setSelectedPartQuantity4(selectedPart4 ? 1 : 0);
        }, [selectedPart4]);
    
        useEffect(() => {
        setSelectedPartQuantity5(selectedPart5 ? 1 : 0);
        }, [selectedPart5]);
    
        useEffect(() => {
        setSelectedPartQuantity6(selectedPart6 ? 1 : 0);
        }, [selectedPart6]);
    
        // Kiegészítők darabszámának frissítése kiválasztás szerint
        useEffect(() => {
        setSelectedAccessoryQuantity1(selectedAccessoryProduct1 ? 1 : 0);
        }, [selectedAccessoryProduct1]);
    
        useEffect(() => {
        setSelectedAccessoryQuantity2(selectedAccessoryProduct2 ? 1 : 0);
        }, [selectedAccessoryProduct2]);
    
        useEffect(() => {
        setSelectedAccessoryQuantity3(selectedAccessoryProduct3 ? 1 : 0);
        }, [selectedAccessoryProduct3]);
    
        useEffect(() => {
        setSelectedAccessoryQuantity4(selectedAccessoryProduct4 ? 1 : 0);
        }, [selectedAccessoryProduct4]);
    
        useEffect(() => {
        setSelectedAccessoryQuantity5(selectedAccessoryProduct5 ? 1 : 0);
        }, [selectedAccessoryProduct5]);
    
        useEffect(() => {
        setSelectedAccessoryQuantity6(selectedAccessoryProduct6 ? 1 : 0);
        }, [selectedAccessoryProduct6]);

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const response = await fetch(`http://127.0.0.1:5000/api/products/${productId}`);
                if (response.ok) {
                    const productData = await response.json();
                    setProductName(productData.name);
                    setSelectedProductCategory(productData.category);
                    setSelectedProductGroupName(productData.group_name);
                    setSelectedProductColor(productData.color);
                    setSelectedProductQuantityUnit(productData.quantity_unit);
                    setSelectedProductPackingUnit(productData.packing_unit);
                    setProductQuantity(productData.quantity);
                    setProductSize(productData.size);
        
                    if (productData.rental_prices) {
                        setProductDay1Price(productData.rental_prices['1_day']);
                        setProductDay2Price(productData.rental_prices['2_day']);
                        setProductDay3Price(productData.rental_prices['3_day']);
                        setProductDay4Price(productData.rental_prices['4_day']);
                        setProductDay5Price(productData.rental_prices['5_day']);
                        setProductDay6Price(productData.rental_prices['6_day']);
                        setProductDay7Price(productData.rental_prices['7_day']);
                        setProductWeekendPrice(productData.rental_prices['weekend']);
                    }
        
                    // Alkatrészek adatai
                    const initialParts = (productData.parts || []).slice(0, 6);
                    setProductParts(initialParts.map(part => ({
                        part_id: part.part_id,
                        part_name: part.part_name,
                        quantity: part.quantity
                    })));
        
                    // Alapértelmezett értékek beállítása alkatrészekhez
                    setSelectedPart1(initialParts[0]?.part_id || '');
                    setSelectedPartQuantity1(initialParts[0]?.quantity || 0);
                    setSelectedPart2(initialParts[1]?.part_id || '');
                    setSelectedPartQuantity2(initialParts[1]?.quantity || 0);
                    setSelectedPart3(initialParts[2]?.part_id || '');
                    setSelectedPartQuantity3(initialParts[2]?.quantity || 0);
                    setSelectedPart4(initialParts[3]?.part_id || '');
                    setSelectedPartQuantity4(initialParts[3]?.quantity || 0);
                    setSelectedPart5(initialParts[4]?.part_id || '');
                    setSelectedPartQuantity5(initialParts[4]?.quantity || 0);
                    setSelectedPart6(initialParts[5]?.part_id || '');
                    setSelectedPartQuantity6(initialParts[5]?.quantity || 0);
        
                    console.log('Selected Parts:', productData.parts);
                    
                    // Kiegészítők adatai
                    const initialAccessories = (productData.accessories || []).slice(0, 6);
                    setAccessoryProducts(initialAccessories.map(accessory => ({
                        accessory_id: accessory.accessory_id,
                        accessory_name: accessory.accessory_name,
                        quantity: accessory.quantity
                    })));
                    
                    // Alapértelmezett értékek beállítása kiegészítőkhez
                    setSelectedAccessoryProduct1(initialAccessories[0]?.accessory_id || '');
                    setSelectedAccessoryQuantity1(initialAccessories[0]?.quantity || 0);
                    setSelectedAccessoryProduct2(initialAccessories[1]?.accessory_id || '');
                    setSelectedAccessoryQuantity2(initialAccessories[1]?.quantity || 0);
                    setSelectedAccessoryProduct3(initialAccessories[2]?.accessory_id || '');
                    setSelectedAccessoryQuantity3(initialAccessories[2]?.quantity || 0);
                    setSelectedAccessoryProduct4(initialAccessories[3]?.accessory_id || '');
                    setSelectedAccessoryQuantity4(initialAccessories[3]?.quantity || 0);
                    setSelectedAccessoryProduct5(initialAccessories[4]?.accessory_id || '');
                    setSelectedAccessoryQuantity5(initialAccessories[4]?.quantity || 0);
                    setSelectedAccessoryProduct6(initialAccessories[5]?.accessory_id || '');
                    setSelectedAccessoryQuantity6(initialAccessories[5]?.quantity || 0);
        
                    console.log('Selected Accessories:', productData.accessories);
        
                } else {
                    Swal.fire('Error', 'Failed to fetch product details', 'error');
                }
            } catch (error) {
                Swal.fire('Error', 'Failed to connect to server', 'error');
            }
        };

        const fetchProductCategories = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-category/list');
                if (response.ok) {
                    const categories = await response.json();
                    setProductCategory(categories);
                }
            } catch (error) {
                console.error("Failed to fetch product categories", error);
            }
        };

        const fetchProductColors = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-color/list');
                if (response.ok) {
                    const colors = await response.json();
                    setProductColor(colors);
                }
            } catch (error) {
                console.error("Failed to fetch product colors", error);
            }
        };

        const fetchProductQuantityUnits = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-quantity-unit/list');
                if (response.ok) {
                    const units = await response.json();
                    setProductQuantityUnit(units);
                }
            } catch (error) {
                console.error("Failed to fetch quantity units", error);
            }
        };

        const fetchProductPackingUnits = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-packing-unit/list');
                if (response.ok) {
                    const units = await response.json();
                    setProductPackingUnit(units);
                }
            } catch (error) {
                console.error("Failed to fetch packing units", error);
            }
        };

        const fetchProductParts = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/product-parts/list');
                if (response.ok) {
                    const parts = await response.json();
                    setProductParts(parts);
                }
            } catch (error) {
                console.error("Failed to fetch product parts", error);
            }
        };

        const fetchAccessoryProducts = async () => {
            try {
                const response = await fetch('http://127.0.0.1:5000/api/accessory-products/list');
                if (response.ok) {
                    const accessories = await response.json();
                    setAccessoryProducts(accessories);
                }
            } catch (error) {
                console.error("Failed to fetch accessory products", error);
            }
        };

        if (productId && isOpen) {
            fetchProductDetails();
            fetchProductCategories();
            fetchProductColors();
            fetchProductQuantityUnits();
            fetchProductPackingUnits();
            fetchProductParts();
            fetchAccessoryProducts();
        }
    }, [productId, isOpen]);

    const fetchProductGroups = useCallback(async (categoryName) => {
        try {
            const response = await fetch(`http://127.0.0.1:5000/api/product-group/list?category_name=${categoryName}`);
            if (response.ok) {
                const groups = await response.json();
                setProductGroupName(groups);
            }
        } catch (error) {
            console.error("Failed to fetch product groups", error);
        }
    }, []);

    useEffect(() => {
        if (selectedProductCategory) {
            fetchProductGroups(selectedProductCategory);
        }
    }, [selectedProductCategory, fetchProductGroups]);

    const handleSubmit = () => {
        if (!productName.trim()) {
            Swal.fire('Error', 'Product name cannot be empty', 'error');
            return;
        }
        
        const productData = {
            product_name: productName,
            product_category: selectedProductCategory,
            product_group_name: selectedProductGroupName,
            product_color: selectedProductColor,
            product_size: productSize,
            product_quantity: Number(productQuantity),
            product_quantity_unit: selectedProductQuantityUnit,
            product_packing_unit: selectedProductPackingUnit,
            product_prices: {
                '1_day': productDay1Price,
                '2_day': productDay2Price,
                '3_day': productDay3Price,
                '4_day': productDay4Price,
                '5_day': productDay5Price,
                '6_day': productDay6Price,
                '7_day': productDay7Price,
                'weekend': productWeekendPrice
            },
            product_parts: [
                { part: selectedPart1, quantity: selectedPartQuantity1 },
                { part: selectedPart2, quantity: selectedPartQuantity2 },
                { part: selectedPart3, quantity: selectedPartQuantity3 },
                { part: selectedPart4, quantity: selectedPartQuantity4 },
                { part: selectedPart5, quantity: selectedPartQuantity5 },
                { part: selectedPart6, quantity: selectedPartQuantity6 }
            ].filter(partObj => partObj.part && partObj.part !== "Kérem, válasszon!"), // Csak kiválasztott alkatrészek
            accessory_products: [
                { accessory: selectedAccessoryProduct1, quantity: selectedAccessoryQuantity1 },
                { accessory: selectedAccessoryProduct2, quantity: selectedAccessoryQuantity2 },
                { accessory: selectedAccessoryProduct3, quantity: selectedAccessoryQuantity3 },
                { accessory: selectedAccessoryProduct4, quantity: selectedAccessoryQuantity4 },
                { accessory: selectedAccessoryProduct5, quantity: selectedAccessoryQuantity5 },
                { accessory: selectedAccessoryProduct6, quantity: selectedAccessoryQuantity6 }
            ].filter(accessoryObj => accessoryObj.accessory && accessoryObj.accessory !== "Kérem, válasszon!") // Csak kiválasztott kiegészítők
        };

        console.log('Product data being submitted:', productData);
        onUpdate(productData);
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2 className='modal-title'>Termék Szerkesztése</h2>
                <div className='scrollable-content'>
                <Row elements={[
                    { type: 'select', label: 'Kategória:', options: productCategory, onInputChange: (e) => setSelectedProductCategory(e.target.value), inputValue: selectedProductCategory },
                    { type: 'select', label: 'Csoport:', options: productGroupName, onInputChange: (e) => setSelectedProductGroupName(e.target.value), inputValue: selectedProductGroupName }
                ]} />
                <Row elements={[
                    { type: 'select', label: 'Szín:', options: productColor, onInputChange: (e) => setSelectedProductColor(e.target.value), inputValue: selectedProductColor }
                ]} />
                <Row elements={[
                    { type: 'labelField', label: 'Termék Neve:', placeholder: 'Termék Neve', onInputChange: (e) => setProductName(e.target.value), inputValue: productName }
                ]} />
                <Row elements={[
                    { type: 'select', label: 'Mennyiség Egysége:', options: productQuantityUnit, onInputChange: (e) => setSelectedProductQuantityUnit(e.target.value), inputValue: selectedProductQuantityUnit },
                    { type: 'select', label: 'Csomagolás Egysége:', options: productPackingUnit, onInputChange: (e) => setSelectedProductPackingUnit(e.target.value), inputValue: selectedProductPackingUnit }
                ]} />
                <Row elements={[
                    { type: 'labelField', label: 'Mennyiség:', placeholder: 'Mennyiség', onInputChange: (e) => setProductQuantity(e.target.value), inputValue: productQuantity }
                ]} />
                <Row elements={[
                    { type: 'labelField', label: 'Méret:', placeholder: 'Méret', onInputChange: (e) => setProductSize(e.target.value), inputValue: productSize }
                ]} />
                <FeeRow elements={[
                    { type: 'labelField', label: '1 Nap (Ft):', onInputChange: (e) => setProductDay1Price(e.target.value), inputValue: productDay1Price },
                    { type: 'labelField', label: '2 Nap (Ft):', onInputChange: (e) => setProductDay2Price(e.target.value), inputValue: productDay2Price },
                    { type: 'labelField', label: '3 Nap (Ft):', onInputChange: (e) => setProductDay3Price(e.target.value), inputValue: productDay3Price },
                    { type: 'labelField', label: '4 Nap (Ft):', onInputChange: (e) => setProductDay4Price(e.target.value), inputValue: productDay4Price }
                ]} />
                <FeeRow elements={[
                    { type: 'labelField', label: '5 Nap (Ft):', onInputChange: (e) => setProductDay5Price(e.target.value), inputValue: productDay5Price },
                    { type: 'labelField', label: '6 Nap (Ft):', onInputChange: (e) => setProductDay6Price(e.target.value), inputValue: productDay6Price },
                    { type: 'labelField', label: '7 Nap (Ft):', onInputChange: (e) => setProductDay7Price(e.target.value), inputValue: productDay7Price },
                    { type: 'labelField', label: 'Hétvége (Ft):', onInputChange: (e) => setProductWeekendPrice(e.target.value), inputValue: productWeekendPrice }
                ]} />
                {/* Alkatrész Hozzárendelés */}
                <h3>ALAKTRÉSZ HOZZÁRENDELÉS</h3>
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '1# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart1(e.target.value), 
                    inputValue: selectedPart1,
                    quantity: selectedPartQuantity1, 
                    onQuantityChange: (e) => setSelectedPartQuantity1(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '2# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart2(e.target.value), 
                    inputValue: selectedPart2,
                    quantity: selectedPartQuantity2, 
                    onQuantityChange: (e) => setSelectedPartQuantity2(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '3# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart3(e.target.value), 
                    inputValue: selectedPart3,
                    quantity: selectedPartQuantity3, 
                    onQuantityChange: (e) => setSelectedPartQuantity3(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '4# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart4(e.target.value), 
                    inputValue: selectedPart4,
                    quantity: selectedPartQuantity4, 
                    onQuantityChange: (e) => setSelectedPartQuantity4(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '5# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart5(e.target.value), 
                    inputValue: selectedPart5,
                    quantity: selectedPartQuantity5, 
                    onQuantityChange: (e) => setSelectedPartQuantity5(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '6# Alkatész kiválasztás:', 
                    options: productParts, 
                    onInputChange: (e) => setSelectedPart6(e.target.value), 
                    inputValue: selectedPart6,
                    quantity: selectedPartQuantity6, 
                    onQuantityChange: (e) => setSelectedPartQuantity6(Number(e.target.value)) 
                    }
                ]}
                />

                {/* Termékhez Kiegészítő Hozzárendelés */}
                <h3>TERMÉKHEZ KIEGÉSZÍTŐ HOZZÁSRENDELÉS</h3>
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '1# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct1(e.target.value), 
                    inputValue: selectedAccessoryProduct1,
                    quantity: selectedAccessoryQuantity1, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity1(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '2# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct2(e.target.value), 
                    inputValue: selectedAccessoryProduct2,
                    quantity: selectedAccessoryQuantity2, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity2(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '3# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct3(e.target.value), 
                    inputValue: selectedAccessoryProduct3,
                    quantity: selectedAccessoryQuantity3, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity3(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '4# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct4(e.target.value), 
                    inputValue: selectedAccessoryProduct4,
                    quantity: selectedAccessoryQuantity4, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity4(Number(e.target.value)) 
                    }
                ]}
                />
                <Row
                elements={[
                    { 
                    type: 'selectInputfield', 
                    label: '5# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct5(e.target.value), 
                    inputValue: selectedAccessoryProduct5,
                    quantity: selectedAccessoryQuantity5, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity5(Number(e.target.value)) 
                    },
                    { 
                    type: 'selectInputfield', 
                    label: '6# Kiegészítő kiválasztás:', 
                    options: accessoryProducts, 
                    onInputChange: (e) => setSelectedAccessoryProduct6(e.target.value), 
                    inputValue: selectedAccessoryProduct6,
                    quantity: selectedAccessoryQuantity6, 
                    onQuantityChange: (e) => setSelectedAccessoryQuantity6(Number(e.target.value)) 
                    }
                ]}
                /> 
                </div>
                <div className="button-row">
                    <TertiaryButton label="Mégse" onClick={onClose} />
                    <PrimaryButton label="Mentés" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );
};

export default EditProductModal;
