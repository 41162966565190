import React, { useState } from 'react';
import Pagination from '../Pagination/Pagination.js';
import './ScheduleDataTables.css';
import iconEdit from'../../icons/icon_edit.png';
import iconDetails from'../../icons/icon_details.png'

const ScheduleDataTable = () => {
  const generateRandomData = () => {
    return Array.from({ length: 20 }, (_, index) => ({
      id: index + 1,
      name: `Esemény ${index + 1}`,
      duration: `${Math.floor(Math.random() * 10) + 1} nap`,
      location: `Helyszín ${index + 1}`,
      client: `Megrendelő ${index + 1}`,
      other: `Még vm ${index + 1}`,
      any: `Bármi ${index + 1}`,
      selected: false
    }));
  };

  const [data, setData] = useState(generateRandomData());
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentData = data.slice(firstItemIndex, lastItemIndex);

  const handleSelectAll = (isSelected) => {
    const updatedData = data.map((item) => ({ ...item, selected: isSelected }));
    setData(updatedData);
  };

  const handleRowSelect = (id) => {
    const updatedData = data.map((item) => 
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    setData(updatedData);
  };

  return (
    <div className="data-table-container">
      <table className="data-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="ui-checkbox" // Új dizájn szerinti checkbox osztály hozzáadva
                onChange={(e) => handleSelectAll(e.target.checked)}
              />
            </th>
            <th>Esemény neve</th>
            <th>Hossza (nap)</th>
            <th>Helyszín</th>
            <th>Megrendelő</th>
            <th>Még vm</th>
            <th>Bármi</th>
            <th>Szerk</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  type="checkbox"
                  className="ui-checkbox" // Új dizájn szerinti checkbox osztály hozzáadva
                  checked={row.selected}
                  onChange={() => handleRowSelect(row.id)}
                />
              </td>
              <td>{row.name}</td>
              <td>{row.duration}</td>
              <td>{row.location}</td>
              <td>{row.client}</td>
              <td>{row.other}</td>
              <td>{row.any}</td>
              <td>
                  <img 
                    src={iconEdit} 
                    alt="Open Modal" 
                    style={{ cursor: 'pointer', height: '25px'}} 
                    
                  />
              </td>
              <td>
                  <img 
                    src={iconDetails} 
                    alt="Open Modal" 
                    style={{ cursor: 'pointer', height: '25px'}} 
                    
                  />
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalItems={data.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default ScheduleDataTable;
